import React from 'react';
import Herosection from './HeroSection/Herosection';
import Statsection from './StatSection/Statsection';
import Featuresection from './FeatureSection/Featuresection'; 
import Usecase from './UseCase/usecase';
import Faqsection from './FaqSection/Faqsection';
import Contactsection from '../Mainindex/Contactsection';


const Tprm = () => {
  return (
    <div>
      <nav style={{ display: 'none' }}>
        <ul>
          <li><a href="#herosection">Herosection</a></li>
           <li><a href="#statsection">Statsection</a></li>
          <li><a href="#featuresection">Featuresection</a></li>
          <li><a href= "#usecase">Usecase</a></li>
          <li><a href= "#faqsection">Faqsection</a></li>
          <li><a href= "#contactsection">Contactsection</a></li> 
        </ul>
      </nav>

      <div id="herosection">
        <Herosection />
      </div>

      <div id="statsection">
        <Statsection />
      </div>

     <div id="featuresection">
        <Featuresection />
      </div>

      <div id="usecase">
        <Usecase />
      </div>

       <div id="faqsection">
        <Faqsection />
      </div>

      <div id="contactsection">
        <Contactsection />
      </div> 
      
    </div>
  );
};

export default Tprm;





