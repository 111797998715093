import React from 'react';
import '../Css/all_style.css';

const ProductSection = () => (
  <div className="product-section">
    <h2 className="product-title">Products</h2>
    <p className="product-description">
      Strengthen your organization's defenses. Discover how our comprehensive security solutions can help you take
      control of your attack surface and manage risk effectively.
    </p>
  </div>
);

export default ProductSection;
