import React, { useState, useEffect } from 'react';
import '../../Css/all_style.css';
import { Link } from 'react-router-dom';
import Requestdemo from '../../Requestdemo/Requestdemo';

const Herosection = () => {
  const [isSidebarActive, setSidebarActive] = useState(false);
  const [hoverInfo, setHoverInfo] = useState({ visible: false, heading: '', text: '', x: 0, y: 0 });

  const openSidebar = () => {
    setSidebarActive(true);
  };

  const closeSidebar = () => {
    setSidebarActive(false);
  };

  useEffect(() => {
    const watchDemoButton = document.getElementById('watch-demo');
    const modal = document.getElementById('demo-modal');
    const iframe = document.getElementById('youtube-video');

    const openModal = (event) => {
      event.preventDefault();
      modal.style.display = 'block';
    };

    const closeModal = () => {
      modal.style.display = 'none';
      const src = iframe.src;
      iframe.src = '';
      iframe.src = src;
    };

    watchDemoButton.addEventListener('click', openModal);

    window.onclick = function (event) {
      if (event.target === modal) {
        closeModal();
      }
    };

    return () => {
      watchDemoButton.removeEventListener('click', openModal);
      window.onclick = null;
    };
  }, []);

  const handleMouseEnter = (event, heading, text) => {
    const { clientX, clientY } = event;
    setHoverInfo({ visible: true, heading, text, x: clientX, y: clientY });
  };

  const handleMouseLeave = () => {
    setHoverInfo({ visible: false, heading: '', text: '', x: 0, y: 0 });
  };

  return (
    <div>
      <div id="top">
        <div className="product-hero-section">
          <video className="background-video" autoPlay loop muted playsInline>
            <source src="img/bg_video1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="product-hero-content">
            <h4>Cloud Security Posture Management (CSPM)</h4>
            <h1>
              Cloud Security Made Easy Automated.<br /> Proactive
            </h1>
            <p>
              Continuously monitor your cloud environment, Identify misconfigurations, and proactively address
              threats before they can compromise your security.
            </p>
            <div className="button-container">
              <Link className="button button-primary" onClick={openSidebar}>Schedule A Demo</Link>
              <a id="watch-demo" href="#watch-demo" className="button button-secondary">Watch demo</a>
            </div>
            <div className="product-hero-image">
              <img src="img/CSPM_Product_page/Group 289146.svg" alt="Dashboard Preview" useMap="#image-map" />
              <map name="image-map">
                <area
                  target=""
                  alt=""
                  title=""
                  href=""
                  coords="115,135,381,318"
                  shape="rect"
                  onMouseEnter={(e) => handleMouseEnter(e, 'Overall Risk Rating', 'Quantify and visualize the overall cloud risk across all your cloud environments.')}
                  onMouseLeave={handleMouseLeave}
                />
                <area
                  target=""
                  alt=""
                  title=""
                  href=""
                  coords="12,464,386,711"
                  shape="rect"
                  onMouseEnter={(e) => handleMouseEnter(e, 'Threat Summary', 'Quickly review a summary of identified threats across your cloud environments.')}
                  onMouseLeave={handleMouseLeave}
                />
                <area
                  target=""
                  alt=""
                  title=""
                  href=""
                  coords="396,96,1092,337"
                  shape="rect"
                  onMouseEnter={(e) => handleMouseEnter(e, 'Resource Distribution', 'Visualize the distribution of resources across all your cloud environments.')}
                  onMouseLeave={handleMouseLeave}
                />
                <area
                  target=""
                  alt=""
                  title=""
                  href=""
                  coords="678,463,1040,676"
                  shape="rect"
                  onMouseEnter={(e) => handleMouseEnter(e, 'Compliance Posture', "Evaluate your cloud environments’ alignment with compliance standards and best practices.")}
                  onMouseLeave={handleMouseLeave}
                />
              </map>
            </div>
          </div>
        </div>
      </div>
      {/* Sidebar */}
      <Requestdemo isActive={isSidebarActive} closeForm={closeSidebar} />

      <div id="demo-modal" className="modal" style={{ display: 'none', position: 'fixed', zIndex: '100', left: '0', top: '0', width: '100%', height: '100%', overflow: 'auto', backgroundColor: 'rgba(0,0,0,0.4)' }}>
        <div className="modal-content" style={{ backgroundColor: '#fefefe', margin: '1% auto', padding: '20px', border: '1px solid #888', width: '100%', maxWidth: '800px' }}>
          <iframe id="youtube-video" width="100%" height="450" src="https://www.youtube.com/embed/eXcGksW_Uyo?si=Wn3GWkAXfbCXVCCB" title="SecureNexus CSPM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
      {hoverInfo.visible && (
        <div className="tooltip"
        style={{
          position: 'absolute',
          top: hoverInfo.y + 300, // Adjusting for cursor size
          left: hoverInfo.x + 0, // Adjusting for cursor size
          backgroundColor: 'white',
          border: '0px solid black',
          padding: '10px',
          zIndex: 1000,
          pointerEvents: 'none',
          maxWidth: '50%',
        }}>
          <h6>{hoverInfo.heading}</h6>
          <p>{hoverInfo.text}</p>
        </div>
      )}
    </div>
  );
};

export default Herosection;
