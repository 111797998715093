import React from 'react';
import Herosection from './Herosection';
import Achivementsection from './Achivementsection';
import Reportsection from './Reportsection';
import Tabsection from './Tabsection';
import Servicesection from './Servicesection';
import Whyussection from './Whyussection';
import Breachsection from './Breachsection';
import Faqsection from './Faqsection';
import Contactsection from './Contactsection';
import Productsection from './Productsection';
// import Scrolltop from './ScrollTop/Scrolltop';

const Index = () => {
  return (
    <div>
      <nav style={{ display: 'none' }}>
        <ul>
          <li><a to="#herosection">Herosection</a></li>
          <li><a to="#achivementsection">Achivementsection</a></li>
          <li><a to="#reportsection">Reportsection</a></li>
          <li><a to="#productsection">Productsection</a></li>
          <li><a to="#tabsection">Tabsection</a></li>
          <li><a to="#servicesection">Servicesection</a></li>
          <li><a to="#whyussection">Whyussection</a></li>
          <li><a to="#breachsection">Breachsection</a></li>
          <li><a to="#faqsection">Faqsection</a></li>
          <li><a to="#Contactsection">Contactsection</a></li>
          {/* <li><a to="#scrolltop">Scrolltop</a></li> */}
        </ul>
      </nav>
 
      <div id="herosection">
        <Herosection />
      </div>
      
      <div id="achivementsection">
        <Achivementsection />
      </div>
      
      <div id="reportsection">
        <Reportsection />
      </div>
      
      <div id="productsection">
        <Productsection />
      </div>
      
      <div id="tabsection">
        <Tabsection />
      </div>

      <div id="servicesection">
        <Servicesection />
      </div>

      <div id="whyussection">
        <Whyussection />
      </div>

      <div id="breachsection">
        <Breachsection />
      </div>

      <div id="faqsection">
        <Faqsection />
      </div>

      <div id="contactsection">
        <Contactsection />
      </div>
    </div>
  );
};
 
export default Index;
