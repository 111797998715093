import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import '../../Css/all_style.css';
import Requestdemo from '../../Requestdemo/Requestdemo';


const Herosection = () => {
  const [isSidebarActive, setSidebarActive] = useState(false);

  const openSidebar = () => {
    setSidebarActive(true);
  };

  const closeSidebar = () => {
    setSidebarActive(false);
  };
    useEffect(() => {
        const watchDemoButton = document.getElementById('watch-demo');
        const modal = document.getElementById('demo-modal');
        const iframe = document.getElementById('youtube-video');
     
        const openModal = (event) => {
          event.preventDefault();
          modal.style.display = 'block';
        };
     
        const closeModal = () => {
          modal.style.display = 'none';
          // Reset the iframe src to stop the video
          const src = iframe.src;
          iframe.src = '';
          iframe.src = src;
        };
     
        watchDemoButton.addEventListener('click', openModal);
     
        window.onclick = function (event) {
          if (event.target === modal) {
            closeModal();
          }
        };
     
        return () => {
          watchDemoButton.removeEventListener('click', openModal);
          window.onclick = null;
        };
      }, []);

    return (
        <div>
            <div id="top">
                <div className="product-hero-section">
                    <video className="background-video" autoPlay loop muted playsInline>
                    <source src="img/bg_video1.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                    <div className="product-hero-content">
                        <h4>Vulnerability Management (VM)</h4>
                        <h1 >Unified View. Actionable
                            Insights.</h1>
                        <p >Consolidate scans from various tools. Prioritize Threats based on real
                            risk, not just volume.<br />
                            Streamline remediation for stronger overall security posture.</p>
                        <div className="button-container">
                        <Link  className="button button-primary"  onClick={openSidebar}>Schedule A Demo</Link>
                            <a id="watch-demo" href="#watch-demo" className="button button-secondary">Watch demo</a>
                        </div>
                        <div className="product-hero-image">
                            <img src="img/VM_Product_pag/Group 289147.svg" alt="Dashboard Preview"/>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sidebar */}
            <Requestdemo isActive={isSidebarActive} closeForm={closeSidebar} />

            <div id="demo-modal" classNameName="modal" style={{display:'none', position:'fixed', zIndex:'100', left:'0', top:'0', width:'100$', height:'100%', overflow:'auto', backgroundColor:'rgba(0,0,0,0.4)'}} >
                <div classNameName="modal-content" style={{backgroundcolor:'#fefefe', margin:'1% auto', padding:'20px', border:'1px solid #888', width:'100%', maxwidth:'800px'}}>
                <iframe id="youtube-video" width="100%" height="450" src="https://www.youtube.com/embed/HpTcsjxPXJ0?si=Ib11AlaEgwCWQtya" title="SecureNexus VM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    );
}

export default Herosection;