import React, { useState } from 'react';
import styled from 'styled-components';

const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #20232a;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.div`
  font-size: 1.5rem;
  color: white;
  font-weight: bold;
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #20232a;
    padding: 1rem;
  }
`;

const NavLink = styled.li`
  margin-left: 2rem;

  & a {
    text-decoration: none;
    color: white;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    margin: 1rem 0;
    text-align: center;
  }
`;

const MobileMenuIcon = styled.div`
  display: none;
  font-size: 2rem;
  color: white;

  @media (max-width: 768px) {
    display: block;
  }
`;

const SampleNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NavbarContainer>
      <Logo>MyLogo</Logo>
      <MobileMenuIcon onClick={toggleMenu}>
        &#9776;
      </MobileMenuIcon>
      <NavLinks isOpen={isOpen}>
        <NavLink><a href="/">Home</a></NavLink>
        <NavLink><a href="/features">Features</a></NavLink>
        <NavLink><a href="/pricing">Pricing</a></NavLink>
        <NavLink><a href="/about">About</a></NavLink>
        <NavLink><a href="/contact">Contact</a></NavLink>
      </NavLinks>
    </NavbarContainer>
  );
};

export default SampleNavbar;