import React, { useState } from 'react';
import '../../Css/all_style.css';

const Faqsection = () => {
    const [activeFaq, setActiveFaq] = useState(null);

    const toggleFaq = (faqId) => {
        setActiveFaq((prevActiveFaq) => (prevActiveFaq === faqId ? null : faqId));
    };

    return (
        <div>
            <section className="faq">
                <div className="product-section">
                    <h2 className="product-title">Frequently Asked Questions</h2>
                </div>
                <div className="faq-wrapper">
                    <div className="faq-container">
                        <div
                            className={`faq-item ${activeFaq === 'faq1' ? 'active' : ''}`}
                            onClick={() => toggleFaq('faq1')}
                        >
                            <h3 className="faq-question">What types of assets does ASM monitor?</h3>
                            <div id="faq1" className="faq-answer" style={{ display: activeFaq === 'faq1' ? 'block' : 'none' }}>
                                <p>ASM provides a complete view of your attack surface, including on-premise and internet-facing assets, applications, networks, and even connected devices. This holistic view helps identify vulnerabilities across your entire IT infrastructure.</p>
                            </div>
                        </div>
                        <div
                            className={`faq-item ${activeFaq === 'faq2' ? 'active' : ''}`}
                            onClick={() => toggleFaq('faq2')}
                        >
                            <h3 className="faq-question">Does ASM offer industry-specific reports or templates?</h3>
                            <div id="faq2" className="faq-answer" style={{ display: activeFaq === 'faq2' ? 'block' : 'none' }}>
                                <p>Yes! ASM offers insight reports that help you with an executive summary or detailed summary of your attack surface. You can also customize dashboards and reports to focus on your organization's specific security metrics.</p>
                            </div>
                        </div>
                        <div
                            className={`faq-item ${activeFaq === 'faq3' ? 'active' : ''}`}
                            onClick={() => toggleFaq('faq3')}
                        >
                            <h3 className="faq-question">How does the CST work alongside ASM?</h3>
                            <div id="faq3" className="faq-answer" style={{ display: activeFaq === 'faq3' ? 'block' : 'none' }}>
                                <p>ASM continuously monitors, while CST adds human expertise. Security analysts review ASM findings, prioritize risks, and perform deeper analysis (like manual pen testing) to provide actionable insights and remediation guidance. This combines automation with human expertise for a more comprehensive security posture.</p>
                            </div>
                        </div>
                        <div
                            className={`faq-item ${activeFaq === 'faq4' ? 'active' : ''}`}
                            onClick={() => toggleFaq('faq4')}
                        >
                            <h3 className="faq-question">Does ASM offer any free trials or demos?</h3>
                            <div id="faq4" className="faq-answer" style={{ display: activeFaq === 'faq4' ? 'block' : 'none' }}>
                                <p>We understand the importance of trying before you buy! SecureNexus ASM offers free trials to explore the platform and see how it benefits your organization. Additionally, our team is happy to provide personalized demos tailored to your specific security needs. Contact us today to learn more!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Faqsection;