import React from 'react';
import '../../Css/all_style.css';
import { Link } from 'react-router-dom';

const UseCaseSection = () => {
  return (
    <div className="use-case-container">
      <div className="use-case-content">
        <div className="use-case-text">
          <h2>Use Case</h2>
          <p>SecureNexus SCA empowers organizations of all sizes to gain complete control over the open-source and third-party components within their codebase. Our advanced solution provides automated analysis and insightful reporting, allowing you to identify and address vulnerabilities before they can be exploited by attackers.</p>
          <p>This proactive approach to code security ensures you're building applications with confidence and mitigating risks associated with external dependencies. SecureNexus SCA offers value across a wide range of use cases.</p>
          <Link to='/about'> <a  className="use-case-btn">Know More</a></Link>
          </div>
        <div className="use-case-image">
          <img src="img/SCA_Product_page/image 111.svg" alt="Use CaseImage" />
        </div>
      </div>
    </div>
  );
};

export default UseCaseSection;
