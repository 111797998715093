import React, { useState, useEffect } from 'react';
import '../../Css/all_style.css';
import { Link } from 'react-router-dom';
import Requestdemo from '../../Requestdemo/Requestdemo';

const ProductHeroSection = () => {
  const [isSidebarActive, setSidebarActive] = useState(false);
  const [hoverInfo, setHoverInfo] = useState({ visible: false, heading: '', text: '', x: 0, y: 0 });

  const openSidebar = () => {
    setSidebarActive(true);
  };

  const closeSidebar = () => {
    setSidebarActive(false);
  };

  useEffect(() => {
    const watchDemoButton = document.getElementById('watch-demo');
    const modal = document.getElementById('demo-modal');
    const iframe = document.getElementById('youtube-video');

    const openModal = (event) => {
      event.preventDefault();
      modal.style.display = 'block';
    };

    const closeModal = () => {
      modal.style.display = 'none';
      const src = iframe.src;
      iframe.src = '';
      iframe.src = src;
    };

    watchDemoButton.addEventListener('click', openModal);

    window.onclick = function (event) {
      if (event.target === modal) {
        closeModal();
      }
    };

    return () => {
      watchDemoButton.removeEventListener('click', openModal);
      window.onclick = null;
    };
  }, []);

  const handleMouseEnter = (event, heading, text) => {
    const { clientX, clientY } = event;
    setHoverInfo({ visible: true, heading, text, x: clientX, y: clientY });
  };

  const handleMouseLeave = () => {
    setHoverInfo({ visible: false, heading: '', text: '', x: 0, y: 0 });
  };

  return (
    <div id="top">
      <div className="product-hero-section">
        <video className="background-video" autoPlay loop muted playsInline>
          <source src="img/bg_video1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="product-hero-content">
          <h4>Software Composition Analysis (SCA)</h4>
          <h1 style={{
            whiteSpace: 'wrap',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>Know Your Code. Own Your Security.</h1>
          <p>From legacy code to cutting-edge applications, SecureNexus SCA provides a comprehensive view of your codebase. Identify prioritize and remediate your vulnerabilities. Own Your Security</p>
          <div className="button-container">
            <Link className="button button-primary" onClick={openSidebar}>Schedule A Demo</Link>
            <a id="watch-demo" href="#watch-demo" className="button button-secondary"> Watch demo</a>
          </div>
          <div className="product-hero-image">
            <img src="img/SCA_Product_page/Group 289144.svg" alt="Dashboard Preview" useMap="#image-map" />
            <map name="image-map">
              <area
                target=""
                alt=""
                title=""
                href=""
                coords="12,504,450,736"
                shape="rect"
                onMouseEnter={(e) => handleMouseEnter(e, 'Governance Policies', 'Ensure compliance and enforce governance policies across your software projects.')}
                onMouseLeave={handleMouseLeave}
              />
              <area
                target=""
                alt=""
                title=""
                href=""
                coords="619,491,116,360"
                shape="rect"
                onMouseEnter={(e) => handleMouseEnter(e, 'Project List', 'View issues across categories for all your applications.')}
                onMouseLeave={handleMouseLeave}
              />
              <area
                target=""
                alt=""
                title=""
                href=""
                coords="742,121,1081,359"
                shape="rect"
                onMouseEnter={(e) => handleMouseEnter(e, 'Top Issues', 'Focus on the most critical issues impacting your software security.')}
                onMouseLeave={handleMouseLeave}
              />
              <area
                target=""
                alt=""
                title=""
                href=""
                coords="429,133,726,341"
                shape="rect"
                onMouseEnter={(e) => handleMouseEnter(e, 'Understand Your Software Supply Chain', 'Gain deep insights into your software supply chain to identify and mitigate risks.')}
                onMouseLeave={handleMouseLeave}
              />
            </map>
          </div>
        </div>
      </div>
      {/* Sidebar */}
      <Requestdemo isActive={isSidebarActive} closeForm={closeSidebar} />

      {/* Modal */}
      <div id="demo-modal" className="modal" style={{ display: 'none', position: 'fixed', zIndex: '100', left: '0', top: '0', width: '100$', height: '100%', overflow: 'auto', backgroundColor: 'rgba(0,0,0,0.4)' }}>
        <div className="modal-content">
          <iframe id="youtube-video" width="100%" height="450" src="https://www.youtube.com/embed/-hbblt_GNSM?si=c7_BQUKh5anPWxTk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
      {hoverInfo.visible && (
        <div className="tooltip" 
        style={{
          position: 'absolute',
          top: hoverInfo.y + 300, // Adjusting for cursor size
          left: hoverInfo.x + 0, // Adjusting for cursor size
          backgroundColor: 'white',
          border: '0px solid black',
          padding: '10px',
          zIndex: 1000,
          pointerEvents: 'none',
          maxWidth: '50%',

        }}>
          <h6>{hoverInfo.heading}</h6>
          <p>{hoverInfo.text}</p>
        </div>
      )}
    </div>
  );
};

export default ProductHeroSection;
