import React, { useState } from 'react';
import '../../Css/all_style.css';

const Faqsection = () => {
    const [openFaq, setOpenFaq] = useState(null);

    const toggleFaq = (faqId) => {
        setOpenFaq(openFaq === faqId ? null : faqId);
    };

    return (
        <div>
            <section className="faq">
                <div className="product-section">
                    <h2 className="product-title">Frequently Asked Questions</h2>
                </div>
                <div className="faq-wrapper">
                    <div className="faq-container">
                        <div className={`faq-item ${openFaq === 'faq1' ? 'active' : ''}`} onClick={() => toggleFaq('faq1')}>
                            <h3 className="faq-question">How can SecureNexus VM help my organization improve its overall security posture?</h3>
                            <div id="faq1" className="faq-answer" style={{ display: openFaq === 'faq1' ? 'block' : 'none' }}>
                                <p>SecureNexus VM helps you proactively identify and address vulnerabilities before they can be exploited. It prioritizes remediation based on risk, automates tasks, and provides a clear view of your security posture with a vulnerability scorecard. This allows you to focus resources on the most critical issues and improve your overall security posture.</p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFaq === 'faq2' ? 'active' : ''}`} onClick={() => toggleFaq('faq2')}>
                            <h3 className="faq-question">Does SecureNexus VM provide any automated remediation recommendations for identified vulnerabilities?</h3>
                            <div id="faq2" className="faq-answer" style={{ display: openFaq === 'faq2' ? 'block' : 'none' }}>
                                <p>Yes, SecureNexus VM goes beyond identification. It offers a recommendation engine suggesting remediation steps and prioritizes vulnerabilities based on real-time threat intelligence. By integrating with vulnerability databases, it provides context and potential remediation actions, empowering your team to address vulnerabilities efficiently.</p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFaq === 'faq3' ? 'active' : ''}`} onClick={() => toggleFaq('faq3')}>
                            <h3 className="faq-question">How does SecureNexus VM differentiate between critical, high, medium, and low-risk vulnerabilities?</h3>
                            <div id="faq3" className="faq-answer" style={{ display: openFaq === 'faq3' ? 'block' : 'none' }}>
                                <p>SecureNexus VM uses a multi-factor scoring system that considers factors like impact, exploitability, asset criticality, etc. This comprehensive approach gives you an accurate risk score for each vulnerability, helping you prioritize remediation efforts effectively.</p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFaq === 'faq4' ? 'active' : ''}`} onClick={() => toggleFaq('faq4')}>
                            <h3 className="faq-question">Does SecureNexus VM offer any free trials or demos for evaluation?</h3>
                            <div id="faq4" className="faq-answer" style={{ display: openFaq === 'faq4' ? 'block' : 'none' }}>
                                <p>Yes! Experience SecureNexus VM firsthand with a free trial. Our team is also happy to tailor a demo to your specific needs. Get in touch to learn more!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Faqsection;
