import React from "react";
import '../../Css/all_style.css';

const Featuresection = () => {
    return (
        <div className="product-features-section">
        <div className="product-features-header">
            <h2>Features</h2>
            <p>Manage Third-Party Risk - Build Trusting Partnerships.</p>
        </div>

        <div className="product-features-content">
            <div className="product-features-image">
                <img src="img/TPRM_Product_page/Frame 289168-1.svg" alt="Features Image" />
            </div>

            <div className="product-features-text">
                <div className="feature-item">
                    <div><span className="feature-icon"></span></div>
                    <div className="feature-text">
                        <h3>Streamline Vendor Onboarding</h3>
                        <p>Simplify the process and ensure consistent, thorough evaluation of all potential partners.
                        </p>
                    </div>
                </div>

                <div className="feature-item">
                    <div><span className="feature-icon"></span></div>
                    <div className="feature-text">
                        <h3>Vendor Contract Management</h3>
                        <p>Store and manage vendor contracts within the TPRM platform.</p>
                    </div>
                </div>
            </div>
        </div>

        {/* <!-- Additional Features Section with Reversed Columns --> */}
        <div className="product-features-content reverse-row p20">
            <div className="product-features-text">
                <div className="feature-item">
                    <div><span className="feature-icon"></span></div>
                    <div className="feature-text">
                        <h3>Digital Footprint Analysis</h3>
                        <p>Gain deep insights into your vendor's digital footprint and quantify their Attack Surface
                            Management (ASM) risk for informed decision making.</p>
                    </div>
                </div>
                {/* <!-- More feature items can be added here if necessary --> */}
                <div className="feature-item">
                    <div><span className="feature-icon"></span></div>
                    <div className="feature-text">
                        <h3>Pre-Built Risk Assessments</h3>
                        <p>Leverage pre-built industry specific questionnaires to streamline vendor assessments for
                            common business sectors</p>
                    </div>
                </div>
                <div className="feature-item">
                    <div><span className="feature-icon"></span></div>
                    <div className="feature-text">
                        <h3>Automated Remediation Workflows</h3>
                        <p>Streamline your security response with automated workflows that suggest or even execute
                            remediation actions for identified vulnerabilities.</p>
                    </div>
                </div>
            </div>

            <div className="product-features-image">
                <img src="img/TPRM_Product_page/Frame 289168.svg" alt="Additional Features Image" />
            </div>
        </div>

        <div className="product-features-content">
            <div className="product-features-image">
                <img src="img/TPRM_Product_page/feature3.svg" alt="Features Image" />
            </div>
            <div className="product-features-text">
                <div className="feature-item">
                    <div><span className="feature-icon"></span></div>
                    <div className="feature-text">
                        <h3>Automated Risk Scoring</h3>
                        <p>Gain a data-driven understanding of vendor risk with dynamic scores based on questionnaires
                            and ASM analysis.</p>
                    </div>
                </div>
                <div className="feature-item">
                    <div><span className="feature-icon"></span></div>
                    <div className="feature-text">
                        <h3>Compliance Gap Identification</h3>
                        <p>Identify gaps in compliance and empower group management with centralized visibility into the
                            security posture of all subsidiaries. Access detailed security reports, drill down into
                            specific subsidiary data, and make informed decisions for enterprise-wide security strategy.
                        </p>
                    </div>
                </div>
            </div>
        </div>


        <div className="product-features-content reverse-row">

            <div className="product-features-text">
                <div className="feature-item">
                    <div><span className="feature-icon"></span></div>
                    <div className="feature-text">
                        <h3>Unify & Secure Your Global Enterprise</h3>
                        <p>Gain a single, consolidated view of your third party risk across all subsidiaries, empowering
                            centralized security management and risk mitigation for your entire organization</p>
                    </div>
                </div>

                <div className="feature-item">
                    <div><span className="feature-icon"></span></div>
                    <div className="feature-text">
                        <h3>Group-Level Security Oversight</h3>
                        <p>Empower group management with centralized visibility into the third party risk of all
                            subsidiaries.
                            Access detailed security reports, drill down into specific subsidiary data, and make
                            informed
                            decisions for enterprise-wide security strategy.</p>
                    </div>
                </div>
            </div>
            <div className="product-features-image">
                <img src="img/feature3.png" alt="Features Image" />
            </div>
        </div>
    </div>
    );
}

export default Featuresection;