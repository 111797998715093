import React from 'react';
import Herosection from './Herosection/Herosection';
import Whyus from './Whyus/Whyus';
import Cyberrisksection from './Cyberrisksection/Cyberrisksection';
import Productsection from './Productsection/Productsection';
import Contactsection from '../Mainindex/Contactsection';

const About = () => {
  return (
    <div>
      <nav style={{ display: 'none' }}>
        <ul>
          <li><a href="#herosection">Herosection</a></li>
          <li><a href="#whyus">Whyus</a></li>
          <li><a href="#cyberrisksection">Cyberrisksection</a></li>
          <li><a href="#productsection">Productsection</a></li>
          <li><a href="#contactsection">Contactsection</a></li>
        </ul>
      </nav>

      <div id="herosection">
        <Herosection />
      </div>

      <div id="whyus">
        <Whyus />
      </div>

      <div id="cyberrisksection">
        <Cyberrisksection />
      </div>

      <div id="productsection">
        <Productsection />
      </div>

      <div id="contactsection">
        <Contactsection />
      </div>
      
    </div>
  );
};

export default About;


// // src/components/About/About.jsx
// import React from 'react';
// import { Link, Outlet } from 'react-router-dom';


// const About = () => {
//   return (
//     <div>
//       <nav>
//         <ul>
//           <li><Link to="Herosection">Herosection</Link></li>
//           <li><Link to="Whyus">Whyus</Link></li>
//           <li><Link to="Cyberrisksection">Cyberrisksection</Link></li>
//           <li><Link to="Productsection">Productsection</Link></li>
//           {/* Add links for other sections */}
//         </ul>
//       </nav>
//       <Outlet />
//     </div>
//   );
// };

// export default About;



