import React from "react";
import '../../Css/all_style.css';

const Featuresection = () => {
    return (
        <div className="product-features-section">
            <div className="product-features-header">
                <h2>Features</h2>
                <p>From Detection to Resolution - Streamlined Vulnerability Management.</p>
            </div>

            <div className="product-features-content">
                <div className="product-features-image">
                    <img src="img/VM_Product_pag/Frame 289168.svg" alt="Features Image" />
                </div>

                <div className="product-features-text">
                    <div className="feature-item">
                        <div><span className="feature-icon"></span></div>
                        <div className="feature-text">
                            <h3>Unified Vulnerability View </h3>
                            <p>Consolidate scans from various tools (SAS,DAST) for a comprehensive vulnerability overview.
                            </p>
                        </div>
                    </div>

                    <div className="feature-item">
                        <div><span className="feature-icon"></span></div>
                        <div className="feature-text">
                            <h3>Risk Based prioritization</h3>
                            <p>Focus remediation efforts on high risk vulnerabilities with real time threat intelligence.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- Additional Features Section with Reversed Columns --> */}
            <div className="product-features-section">
                <div className="product-features-content reverse-row">
                    <div className="product-features-text">
                        <div className="feature-item">
                            <div><span className="feature-icon"></span></div>
                            <div className="feature-text">
                                <h3>Seamless Integration</h3>
                                <p>Effortlessly import vulnerability data from existing security tools for a unified view.
                                </p>
                            </div>
                        </div>
                        {/* <!-- More feature items can be added here if necessary --> */}
                        <div className="feature-item">
                            <div><span className="feature-icon"></span></div>
                            <div className="feature-text">
                                <h3>Streamlined Audit Management</h3>
                                <p>Effortlessly plan, schedule and track vulnerability audits for clear and efficient
                                    remediation process.</p>
                            </div>
                        </div>
                    </div>

                    <div className="product-features-image">
                        <img src="img/VM_Product_pag/Frame 427318699.svg" alt="Additional Features Image" />
                    </div>
                </div>

                <div className="product-features-content ">
                    <div className="product-features-image">
                        <img src="img/VM_Product_pag/Group 289164.svg" alt="Additional Features Image" />
                    </div>
                    <div className="product-features-text">
                        <div className="feature-item">
                            <div><span className="feature-icon"></span></div>
                            <div className="feature-text">
                                <h3>Automated Workflows</h3>
                                <p>Streamline vulnerability management with scheduled scans, automated reporting, and
                                    lifecycle tracking.
                                </p>
                            </div>
                        </div>
                        {/* <!-- More feature items can be added here if necessary --> */}
                        <div className="feature-item">
                            <div><span className="feature-icon"></span></div>
                            <div className="feature-text">
                                <h3>Actionable Insights & Recommendations</h3>
                                <p>Leverage a recommendation engine and escaltion matrix to prioritize vulnerabilities and
                                    guide remediation steps.</p>
                            </div>
                        </div>
                    </div>


                </div>


                <div className="product-features-content reverse-row">


                    <div className="product-features-text">
                        <div className="feature-item">
                            <div><span className="feature-icon"></span></div>
                            <div className="feature-text">
                                <h3>Unify & Secure Your Global Enterprise</h3>
                                <p>Gain a single, consolidated view of your vulnerabilities across all subsidiaries,
                                    empowering centralized security management and risk mitigation for your entire
                                    organization</p>
                            </div>
                        </div>

                        <div className="feature-item">
                            <div><span className="feature-icon"></span></div>
                            <div className="feature-text">
                                <h3>Group-Level Security Oversight</h3>
                                <p>Empower group management with centralized visibility into the vulnerabilities of all
                                    subsidiaries. Access detailed security reports, drill down into specific subsidiary
                                    data, and make informed decisions for enterprise-wide security strategy.</p>
                            </div>
                        </div>
                    </div>
                    <div className="product-features-image">
                        <img src="img/feature3.png" alt="Features Image" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Featuresection;
