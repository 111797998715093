import React from 'react';
import '../../Css/all_style.css';

const Cyberrisksection = () => {
    return (
        <div>
            <div className="cyber-risk-section">
                <div className="cyber-section-heading">
                    <h2>One-stop solution<br/>for all your cybersecurity needs</h2>
                    <p>Enhance your cyber resilience with our tailored strategies and proactive approach - Partner with Secure Nexus to safeguard your digital assets.</p>
                </div>

                <div className="cyber-features-container">
                    <div className="cyber-feature-item">
                        <img src="/img/cyber1.png" alt="Industry-leading professionals"/>
                        <h3>Industry-leading professionals ensuring exceptional security.</h3>
                    </div>

                    <div className="cyber-feature-item">
                        <img src="/img/cyber2.png" alt="Holistic approach"/>
                        <h3>Holistic approach covering all security aspects.</h3>
                    </div>

                    <div className="cyber-feature-item">
                        <img src="/img/cyber3.png" alt="Trusted choice"/>
                        <h3>Trusted choice with successful outcomes.</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cyberrisksection;