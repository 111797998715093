import React from 'react';
import '../../Css/all_style.css';

function PrivacyPolicyHero() {
  return (
    <div id="top">
      <div className="about-hero-section">
        <video className="background-video" autoPlay loop muted playsInline>
          <source src="img/bg_video1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="about-hero-content">
          <h1>SecureNexus Privacy Policy</h1>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyHero;
