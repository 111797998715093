import React from 'react';
import '../../Css/all_style.css';

const Herosection = () => {
    return (
        <div id="top">
            <div className="about-hero-section">
                    <video className="background-video" autoPlay loop muted playsInline>
                        <source src="img/bg_video1.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                <div className="about-hero-content">
                    <h4>About Us</h4>
                    <h1>Securing the Digital Nexus of Forces</h1>
                </div>
            </div>
        </div>
    );
}

export default Herosection;