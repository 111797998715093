import React, { useState, useEffect } from 'react';
import '../../Css/all_style.css';
import { Link } from 'react-router-dom';
import Requestdemo from '../../Requestdemo/Requestdemo';

const Herosection = () => {
  const [isSidebarActive, setSidebarActive] = useState(false);
  const [hoverInfo, setHoverInfo] = useState({ visible: false, text: '', x: 0, y: 0 });

  const openSidebar = () => {
    setSidebarActive(true);
  };

  const closeSidebar = () => {
    setSidebarActive(false);
  };

  useEffect(() => {
    const watchDemoButton = document.getElementById('watch-demo');
    const modal = document.getElementById('demo-modal');
    const iframe = document.getElementById('youtube-video');

    const openModal = (event) => {
      event.preventDefault();
      modal.style.display = 'block';
    };

    const closeModal = () => {
      modal.style.display = 'none';
      const src = iframe.src;
      iframe.src = '';
      iframe.src = src;
    };

    watchDemoButton.addEventListener('click', openModal);

    window.onclick = function (event) {
      if (event.target === modal) {
        closeModal();
      }
    };

    return () => {
      watchDemoButton.removeEventListener('click', openModal);
      window.onclick = null;
    };
  }, []);

  const handleMouseEnter = (event,heading, text) => {
    const { clientX, clientY } = event;
    setHoverInfo({ visible: true, heading, text, x: clientX, y: clientY });
  };

  const handleMouseLeave = () => {
    setHoverInfo({ visible: false,heading: '', text: '', x: 0, y: 0 });
  };

  return (
    <div>
      <div id="top">
        <div className="product-hero-section">
          <video className="background-video" autoPlay loop muted playsInline>
            <source src="img/bg_video1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="product-hero-content">
            <h4>Full Attack Surface Management (F-ASM)</h4>
            <h1>See it. Secure it.</h1>
            <p>Gain comprehensive visibility into your entire attack surface with continuous monitoring. Unify your security posture and proactively conquer cyber threats.</p>
            <div className="button-container">
              <Link className="button button-primary" onClick={openSidebar}>Schedule A Demo</Link>
              <a id="watch-demo" href="#watch-demo" className="button button-secondary">Watch demo</a>
            </div>
            <div className="product-hero-image">
              <img src="img/FASM_Product_page/mainimage.svg" alt="Dashboard Preview" useMap="#image-map" />
              <map name="image-map">
                <area
                  target=""
                  alt=""
                  title=""
                  href=""
                  coords="169,569,360,725"
                  shape="rect"
                  onMouseEnter={(e) => handleMouseEnter(e,  'Threat Summary',  'Quickly review a concise summary of identified threats and vulnerabilities.' )}
                  onMouseLeave={handleMouseLeave}
                />
                <area
                  target=""
                  alt=""
                  title=""
                  href=""
                  coords="13,222,292,414"
                  shape="rect"
                  onMouseEnter={(e) => handleMouseEnter(e, 'Visibility Of Assets', 'Gain comprehensive visibility into your digital assets across the entire attack surface.' )}
                  onMouseLeave={handleMouseLeave}
                />
                <area
                  target=""
                  alt=""
                  title=""
                  href=""
                  coords="146,133,421,210"
                  shape="rect"
                  onMouseEnter={(e) => handleMouseEnter(e, 'Risk Rating',  'Assess and prioritize risks with our dynamic risk rating system.' )}
                  onMouseLeave={handleMouseLeave}
                />
                <area
                  target=""
                  alt=""
                  title=""
                  href=""
                  coords="925,74,1140,311"
                  shape="rect"
                  onMouseEnter={(e) => handleMouseEnter(e,  'Analyze Risk Across Categories',  'Uncover and evaluate risks across diverse categories within your attack surface.' )}
                  onMouseLeave={handleMouseLeave}
                />
              </map>
            </div> 
          </div>
        </div>
      </div>
      <Requestdemo isActive={isSidebarActive} closeForm={closeSidebar} />

      <div id="demo-modal" className="modal" style={{ display: 'none', position: 'fixed', zIndex: '100', left: '0', top: '0', width: '100%', height: '100%', overflow: 'auto', backgroundColor: 'rgba(0,0,0,0.4)' }}>
        <div className="modal-content" style={{ backgroundColor: '#fefefe', margin: '1% auto', padding: '20px', border: '1px solid #888', width: '100%', maxWidth: '800px' }}>
          <iframe id="youtube-video" width="100%" height="450" src="https://www.youtube.com/embed/h82tQHrupmY?si=PUVoqRHoYVLTvi4r" title="SecureNexus FASM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
      {hoverInfo.visible && (
        <div
          className="tooltip"
          style={{
            position: 'absolute',
            top: hoverInfo.y + 300, // Adjusting for cursor size
            left: hoverInfo.x + 0, // Adjusting for cursor size
            backgroundColor: 'white',
            border: '0px solid black',
            padding: '10px',
            zIndex: 1000,
            pointerEvents: 'none',
            maxWidth: '50%',

          }}
        >
          <h6>{hoverInfo.heading}</h6>
          <p>{hoverInfo.text}</p>
        </div>
      )}
   
    </div>
  );
}

export default Herosection;
