// import React from 'react';
import React, { useState, useEffect, useRef } from 'react';
import '../Css/all_style.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountUp from 'react-countup';

const Achivementsection = () => {
  const [threatsDiscovered, setThreatsDiscovered] = useState(0);
  const [threatsResolved, setThreatsResolved] = useState(0);
  const [customerSatisfaction, setCustomerSatisfaction] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Reset states to 0 when element comes into view
          setThreatsDiscovered(0);
          setThreatsResolved(0);
          setCustomerSatisfaction(0);

          const animateNumbers = (setter, target, increment) => {
            const interval = setInterval(() => {
              setter(prev => {
                if (prev >= target) {
                  clearInterval(interval);
                  return target;
                }
                return prev + increment;
              });
            }, 30);
          };

          animateNumbers(setThreatsDiscovered, 240, 2);
          animateNumbers(setThreatsResolved, 125, 3);
          animateNumbers(setCustomerSatisfaction, 99, 2);
        }
      },
      { threshold: 0.05 } // Trigger when 10% of the component is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} className="section-achievements">
      <Row>
        <AnimatedNumbers image={'threat-discovered.svg'} number={`${threatsDiscovered}K`} label={'Threats Discovered'}/>
        <AnimatedNumbers image={'threat-resolved.svg'} number={`${threatsResolved}K`} label={'Threats Resolved'}/>
        <AnimatedNumbers image={'customer-satisfaction.svg'} number={`${customerSatisfaction}%`} label={'Customer Satisfaction'}/>
      </Row>
    </div>
  );
};

export default Achivementsection;


const AnimatedNumbers = ({image, number, label}) => {
  return (
    <Col xs={12} sm={4} md={4}>
      <div className='stat-container'>
        <div style={{display: "inline-flex"}}>
          <img src={`img/home page/${image}`} alt="achievement Image"/>
          <div className='stat-content'>
            <div className='big-number'>{number}</div>
            <div className="stat-title">{label}</div>
          </div>
        </div>
      </div>
    </Col>
  );
}
