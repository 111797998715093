import React from 'react';

const FAQSection = () => {
  const toggleFaq = (faqId) => {
    const answer = document.getElementById(faqId);
    const isActive = answer.style.display === 'block';

    // Close all open answers
    const allAnswers = document.querySelectorAll('.faq-answer');
    allAnswers.forEach((ans) => {
      ans.style.display = 'none';
      ans.parentNode.classList.remove('active');
    });

    // Toggle the clicked answer
    if (isActive) {
      answer.style.display = 'none';
    } else {
      answer.style.display = 'block';
    }
  };

  return (
    <section className="faq">
      <div className="product-section">
        <h2 className="product-title">Frequently Asked Questions</h2>
      </div>
      <div className="faq-wrapper">
        <div className="faq-container">
          <div className="faq-item" onClick={() => toggleFaq('faq1')}>
            <h3 className="faq-question">How does SCA integrate into the development workflow?</h3>
            <div id="faq1" className="faq-answer">
              <p>SecureNexus SCA seamlessly integrates with your development lifecycle. It can be set up to automatically scan code during pull requests, CI/CD pipelines, or at scheduled intervals. This allows developers to identify and address vulnerabilities early in the development process, minimizing delays and rework.</p>
            </div>
          </div>
          <div className="faq-item" onClick={() => toggleFaq('faq2')}>
            <h3 className="faq-question">What reporting capabilities does SCA offer?</h3>
            <div id="faq2" className="faq-answer">
              <p>SCA offers a variety of reports to help you understand your codebase security posture. These include detailed vulnerability reports, license compliance summaries, and Software Bill of Materials (SBOMs).</p>
            </div>
          </div>
          <div className="faq-item" onClick={() => toggleFaq('faq3')}>
            <h3 className="faq-question">Does SCA offer support for specific coding languages or frameworks?</h3>
            <div id="faq3" className="faq-answer">
              <p>Yes, SecureNexus SCA supports a wide range of programming languages and frameworks, including but not limited to Java, JavaScript, Python, Ruby, and more. Our tool is designed to adapt to various development environments and seamlessly integrate with popular tools and platforms.</p>
            </div>
          </div>
          <div className="faq-item" onClick={() => toggleFaq('faq4')}>
            <h3 className="faq-question">Does SecureNexus offer any free trials or demos for SCA?</h3>
            <div id="faq4" className="faq-answer">
              <p>We understand the importance of trying before you buy! SecureNexus SCA offers free trials that allow you to explore the platform's capabilities and see how it integrates with your development workflow. Additionally, our team is happy to provide personalized demos tailored to your specific needs. Contact us today to learn more!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
