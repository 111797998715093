import React from 'react';
import '../../Css/all_style.css';

const ProductStatsSection = () => {
  return (
    <div className="product-stats-section">
      <div className="product-stats-column">
        <h2>Identify Open Source Vulnerabilities</h2>
        <p>Proactively identify and address security risks in your code.</p>
      </div>
      <div className="product-stats-column product-stats-box">
        <h3>90%</h3>
        <h4>Applications Contain Open Source Code.</h4>
        <p>Minimize risk by securing your open source dependencies.</p>
      </div>
      <div className="product-stats-column product-stats-box">
        <h3>75%</h3>
        <h4>Reduction in Security Review Time.</h4>
        <p>Automate analysis and prioritize critical vulnerabilities.</p>
      </div>
    </div>
  );
};

export default ProductStatsSection;
