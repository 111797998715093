import React from 'react';
import '../../Css/all_style.css';

const Statsection = () => {
    return (
        <div className="product-stats-section">
            <div className="product-stats-column">
                <h2>Proactive Vulnerability Management</h2>
                <p>Identify and assess vulnerabilities before they can be exploited by attackers.</p>
            </div>
            <div className="product-stats-column product-stats-box">
                <h3>70%</h3>
                <h4>Data breaches exploit known vulnerabilities.</h4>
                <p>Reduce your risk with proactive vulnerability management.</p>
            </div>
            <div className="product-stats-column product-stats-box">
                <h3>80%</h3>
                <h4>Security Teams spend 80% Time on low risk vulnerabilities</h4>
                <p>Free up your team to focus on critical threats.</p>
            </div>
        </div>
    );
}

export default Statsection;