import React from "react";
import '../../Css/all_style.css';

const Featuresection = () => {
    return (
        <div className="product-features-section">
            <div className="product-features-header">
                <h2>Features</h2>
                <p>Continuous Cloud Confidence - Leverage Our Advanced CSPM Features</p>
            </div>

            <div className="product-features-content">
                <div className="product-features-image">
                    <img src="img/CSPM_Product_page/Frame 289168.svg" alt="Features Image" />
                </div>

                <div className="product-features-text">
                    <div className="feature-item">
                        <div><span className="feature-icon"></span></div>
                        <div className="feature-text">
                            <h3>Continuous Misconfigurations Detection</h3>
                            <p>Identify and address security risks caused by cloud misconfigurations before they can be
                                exploited.</p>
                        </div>
                    </div>

                    <div className="feature-item">
                        <div><span className="feature-icon"></span></div>
                        <div className="feature-text">
                            <h3>Cloud Native Security</h3>
                            <p>Secure your cloud-native applications with features like container image scanning, serverless
                                function monitoring, and API security assessments.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Additional Features Section with Reversed Columns --> */}
            <div className="product-features-content reverse-row">
                <div className="product-features-text">
                    <div className="feature-item">
                        <div><span className="feature-icon"></span></div>
                        <div className="feature-text">
                            <h3>Simplified Compliance Management</h3>
                            <p>Monitor Compliance with industry standards and regulations, ensuring your cloud
                                infrastructure remains compliant.</p>
                        </div>
                    </div>
                    {/* <!-- More feature items can be added here if necessary --> */}
                    <div className="feature-item">
                        <div><span className="feature-icon"></span></div>
                        <div className="feature-text">
                            <h3>Cloud Cost Optimization</h3>
                            <p>Identify unused or underutilized resources to optimize your cloud spending and improve cost
                                efficiency.</p>
                        </div>
                    </div>
                    <div className="feature-item">
                        <div><span className="feature-icon"></span></div>
                        <div className="feature-text">
                            <h3>Global Cloud Asset Visualization</h3>
                            <p>See all your cloud resources in one place, regardless of their geographic location.</p>
                        </div>
                    </div>
                    <div className="feature-item">
                        <div><span className="feature-icon"></span></div>
                        <div className="feature-text">
                            <h3>Automated Remediation Workflows</h3>
                            <p>Streamline your security response with automated workflows that suggest or even execute
                                remediation actions for identified vulnerabilities.</p>
                        </div>
                    </div>
                </div>

                <div className="product-features-image">
                    <img src="img/CSPM_Product_page/Group 289161.svg" alt="Additional Features Image" />
                </div>
            </div>

            <div className="product-features-content">
                <div className="product-features-image">
                    <img src="img/feature3.png" alt="Features Image" />
                </div>

                <div className="product-features-text">
                    <div className="feature-item">
                        <div><span className="feature-icon"></span></div>
                        <div className="feature-text">
                            <h3>Unify & Secure Your Global Enterprise</h3>
                            <p>Gain a single, consolidated view of your attack surface across all subsidiaries, empowering
                                centralized security management and risk mitigation for your entire organization</p>
                        </div>
                    </div>

                    <div className="feature-item">
                        <div><span className="feature-icon"></span></div>
                        <div className="feature-text">
                            <h3>Group-Level Security Oversight</h3>
                            <p>Empower group management with centralized visibility into the security posture of all
                                subsidiaries. Access detailed security reports, drill down into specific subsidiary data,
                                and make informed decisions for enterprise-wide security strategy.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Featuresection;