import React from 'react';
import Herosection from './Herosection/Herosection';
import Productstate from './Productstate/Productstate';
import Productfeature from './Productfeature/Productfeature';
import Usecase from './Usecase/Usecase';
import Faq from './Faq/Faq';
import Contactsection from '../Mainindex/Contactsection';

const Sca = () => {
  return (
    <div>
      <nav style={{ display: 'none' }}>
        <ul>
          <li><a href="#herosection">Herosection</a></li>
          <li><a href="#productstate">Productstate</a></li>
          <li><a href="#productfeature">Productfeature</a></li>
          <li><a href="#usecase">Usecase</a></li>
          <li><a href="#faq">Faq</a></li>
          <li><a href="#contactsection">Contactsection</a></li>
          
        </ul>
      </nav>

      <div id="herosection">
        <Herosection />
      </div>

      <div id="productstate">
        <Productstate />
      </div>

      <div id="productfeature">
        <Productfeature />
      </div>

      <div id="usecase">
        <Usecase />
      </div>
      
      <div id="faq">
        <Faq />
      </div>
      
      <div id="contactsection">
        <Contactsection />
      </div>
    </div>
  );
};

export default Sca;

