import React from 'react';
import '../../Css/all_style.css';

const ProductFeaturesSection = () => {
  return (
    <div className="product-features-section">
      <div className="product-features-header">
        <h2>Features</h2>
        <p>Safeguard Your Software Supply Chain with SecureNexus SCA</p>
      </div>

      <div className="product-features-content">
        <div className="product-features-image">
          <img src="img/SCA_Product_page/Frame 289168-2.svg" alt="FeaturesImage" />
        </div>
        <div className="product-features-text">
          <div className="feature-item">
            <div><span className="feature-icon"></span></div>
            <div className="feature-text">
              <h3>Automated Dependency Mapping</h3>
              <p>Gain instant visibility into all open-source and third-party components used in your code.</p>
            </div>
          </div>
          <div className="feature-item">
            <div><span className="feature-icon"></span></div>
            <div className="feature-text">
              <h3>SBOM Generation</h3>
              <p>Simplify supply chain management with a comprehensive SBOM of all your software components.</p>
            </div>
          </div>
          <div className="feature-item">
            <div><span className="feature-icon"></span></div>
            <div className="feature-text">
              <h3>Open Source Governance</h3>
              <p>Define and enforce custom policies to manage open-source library usage and ensure alignment with your security requirements.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="product-features-content reverse-row">
        <div className="product-features-text">
          <div className="feature-item">
            <div><span className="feature-icon"></span></div>
            <div className="feature-text">
              <h3>Vulnerability Detection</h3>
              <p>Proactively identify and address security risks within your open-source dependencies.</p>
            </div>
          </div>
          <div className="feature-item">
            <div><span className="feature-icon"></span></div>
            <div className="feature-text">
              <h3>License Compliance Assurance</h3>
              <p>Ensure your software adheres to the licensing regulations and avoid accidental non-compliance.</p>
            </div>
          </div>
          <div className="feature-item">
            <div><span className="feature-icon"></span></div>
            <div className="feature-text">
              <h3>Outdated Components Alerts</h3>
              <p>Receive early warnings about outdated or end-of-life components in your codebase.</p>
            </div>
          </div>
          <div className="feature-item">
            <div><span className="feature-icon"></span></div>
            <div className="feature-text">
              <h3>Malicious Components Scanning</h3>
              <p>Identify malicious components within your codebase to prevent potential breaches and vulnerabilities.</p>
            </div>
          </div>
        </div>

        <div className="product-features-image">
          <img src="img/SCA_Product_page/Frame 289168-1.svg" alt="Additional FeaturesImage" />
        </div>
      </div>

      <div className="product-features-content">
        <div className="product-features-image">
          <img src="img/SCA_Product_page/Frame 289168.svg" alt="FeaturesImage" />
        </div>

        <div className="product-features-text">
          <div className="feature-item">
            <div><span className="feature-icon"></span></div>
            <div className="feature-text">
              <h3>Unify & Secure Your Global Enterprise</h3>
              <p>Gain a single, consolidated view of your software composition across all subsidiaries, empowering centralized security management and risk mitigation for your entire organization.</p>
            </div>
          </div>
          <div className="feature-item">
            <div><span className="feature-icon"></span></div>
            <div className="feature-text">
              <h3>Group-Level Security Oversight</h3>
              <p>Empower group management with centralized visibility into the software composition of all subsidiaries. Access detailed security reports, drill down into specific subsidiary data, and make informed decisions for enterprise-wide security strategy.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFeaturesSection;
