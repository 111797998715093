import React from 'react';
import '../../Css/all_style.css';

function PrivacyPolicyContent() {
  return (
    <section className="why-us">
      <div className="why-us-container">
        <div className="why-us-content" style={{ color:'black'}} >
          <h3 className="why-us-title">Effective Date: 21st April 2024</h3>
          <div className="privacy_content" >
            <h2>Information We Collect</h2>
            <p>We collect several types of information to provide and improve our services:</p>
            <ul>
              <li>Personal Information: This may include your name, email address, phone number, and company information when you create an account, request a demo, contact us, or use our support services.</li>
              <li>Usage Data: We collect data about how you interact with our website and services, such as the pages you visit, features you use, and the content you access.</li>
              <li>Log Data: When you use our services, we automatically collect standard log information, including your IP address, browser type, operating system, access times, and referring website URLs.</li>
              <li>Third-Party Data: We may collect information about you from third-party sources, such as public databases or business partners, to enhance our services and provide a better user experience.</li>
            </ul>
          </div>
          <div className="privacy_content">
            <h2>How We Use Your Information</h2>
            <p>We use the information we collect for several purposes:</p>
            <ul>
              <li>To provide and improve our services, including account management, customer support, and product development.</li>
              <li>To personalize your experience by tailoring the content and features you see.</li>
              <li>To send you important information about our services, including updates, security alerts, and support messages.</li>
              <li>To comply with legal and regulatory requirements.</li>
            </ul>
          </div>
          <div className="privacy_content">
            <h2>Sharing Your Information</h2>
            <p>We may share your information with third-party service providers who help us operate our business and provide our services. These service providers are contractually obligated to keep your information confidential and secure.</p>
            <p>We will not share your personal information with third-party marketers without your consent. However, we may share aggregate or anonymized data with third parties for research or marketing purposes.</p>
          </div>
          <div className="privacy_content">
            <h2>Your Choices</h2>
            <p>You have choices regarding your information:</p>
            <ul>
              <li>Marketing Opt-Out: You can unsubscribe from our marketing communications by clicking the "unsubscribe" link in any email or by contacting us.</li>
              <li>Data Retention: We will retain your information for as long as necessary to provide our services, comply with legal obligations, or resolve disputes.</li>
            </ul>
          </div>
          <div className="privacy_content">
            <h2>Security</h2>
            <p>We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure.</p>
          </div>
          <div className="privacy_content">
            <h2>Children's Privacy</h2>
            <p>Our services are not directed to children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us.</p>
          </div>
          <div className="privacy_content">
            <h2>Changes to this Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicyContent;
