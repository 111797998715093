import React from "react";
import '../../Css/all_style.css';

const Statsection = () => {
    return (
        <div className="product-stats-section">
            <div className="product-stats-column">
                <h2>Take Control of Your Attack Surface.</h2>
                <p>Gain Complete Visibility, Secure Your Digital Landscape with Confidence.</p>
            </div>
            <div className="product-stats-column product-stats-box">
                <h3>72%</h3>
                <h4>Reduction in Alert Fatigue</h4>
                <p>ASM automates threat detection and prioritizes critical vulnerabilities, freeing your security team to focus on remediation efforts.</p>
            </div>
            <div className="product-stats-column product-stats-box">
                <h3>90%</h3>
                <h4>Faster Mean Time to Resolution (MTTR)</h4>
                <p>Gain real-time insights and streamline incident response, accelerating your ability to address security issues.</p>
            </div>
        </div>
    );
}

export default Statsection;
