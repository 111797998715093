import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import "./chatbot.css"; // Import your CSS file

const Chatbot = ({ onMinimize }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const messageContainerRef = useRef(null);

  useEffect(() => {
    const savedMessages = Cookies.get("chatHistory");
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
      setLoading(false);
    } else {
      fetchInitialMessage();
    }
  }, []);

  useEffect(() => {
    // Save chat history in cookies whenever messages are updated
    if (messages.length > 0) {
      Cookies.set("chatHistory", JSON.stringify(messages), { expires: 1 });
    }
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const fetchInitialMessage = async () => {
    try {
      const response = await fetch("http://localhost:5000/api/chatbot/initial");
      if (!response.ok) {
        throw new Error("Failed to fetch initial message");
      }
      const data = await response.json();
      setMessages([data]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching initial message:", error);
      setLoading(false);
    }
  };

  const handleUserSelection = async (optionKey, nextMessageKey, optionText) => {
    if (optionKey === "bookDemo") {
      setShowEmailInput(true);
      setMessages([...messages, { messageText: optionText, isUser: true }]);
    } else {
      try {
        const response = await fetch(
          `http://localhost:5000/api/chatbot/select/${nextMessageKey}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch message for ${optionKey}`);
        }
        const data = await response.json();
        setMessages([
          ...messages,
          { messageText: optionText, isUser: true }, // Add user-selected option
          data,
        ]);
      } catch (error) {
        console.error(`Error fetching message for ${optionKey}:`, error);
      }
    }
  };

  const handleEmailSubmit = async () => {
    try {
      const response = await fetch(
        "http://localhost:5000/api/chatbot/save-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save email");
      }

      setMessages([
        ...messages,
        {
          messageText:
            "Great, thanks! Someone from our team will be with you as soon as they can.",
          isUser: false,
        },
      ]);
      setShowEmailInput(false);
    } catch (error) {
      console.error("Error saving email:", error);
    }
  };

  const handleNewChat = () => {
    Cookies.remove("chatHistory");
    setMessages([]);
    setEmail("");
    setShowEmailInput(false);
    fetchInitialMessage();
  };

  const renderOptions = (options) => {
    return (
      <div className="chatbot-options">
        {options.map((option) => (
          <div
            key={option.optionKey}
            className="chatbot-option d-grid justify-content-end"
          >
            <button
              onClick={() =>
                handleUserSelection(
                  option.optionKey,
                  option.nextMessageKey,
                  option.optionText
                )
              }
            >
              {option.optionText}
            </button>
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return <p>Loading...</p>; // loader here in p tag
  }

  return (
    <div className="chatbot-container">
      <div className="chatbot-header">
        Message
        <button className="minimize-button" onClick={onMinimize}>
          ▼
        </button>
      </div>
      <div className="chatbot-messages d-grid" ref={messageContainerRef}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`chatbot-message ${
              message.isUser ? "user-message d-grid justify-content-end" : ""
            }`}
          >
            {message.messageText}
          </div>
        ))}
        <div className="container-fluid">
          <div className="row align-items-end">
            {showEmailInput && (
              <div className="email-input-container col-md-10">
                <input
                  class="form-control"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                />
                <button onClick={handleEmailSubmit}>Submit</button>
              </div>
            )}
            {messages.length > 0 &&
              messages[messages.length - 1].options &&
              renderOptions(messages[messages.length - 1].options)}
            <div className="new-chat-button col-md-2" title="New Chat" onClick={handleNewChat}>
              <img
                className="new-chat-icon"
                src="img/add-message.png"
                alt="New Chat Icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
