import React from "react";
import '../../Css/all_style.css';
import { Link } from 'react-router-dom';

const Usecase = () => {
    return (
        <div className="use-case-container">
            <div className="use-case-content">
                <div className="use-case-text">
                    <h2>Use Case</h2>
                    <p>SecureNexus Vulnerability Management empowers
                        organizations to take control of their security posture by providing the tools and insights needed
                        to proactively identify, prioritize, and remediate vulnerabilities before attackers can exploit
                        them. Our comprehensive solution goes beyond basic scanning, offering a unified view of your
                        security posture and actionable data for informed decision-making.
                        .</p>
                    <ul className="section-list">
                        <li>Streamlined Remediation Workflows</li>
                        <li>Improved Security Posture</li>
                        <li>Simplified Compliance Reporting</li>
                        <li>Prioritized Patch Management</li>
                    </ul>
                    <Link to='/about'> <a  className="use-case-btn">Know More</a></Link>
                    </div>
                <div className="use-case-image">
                    <img src="img/VM_Product_pag/image 129.svg" alt="Use Case Image" />
                </div>
            </div>
        </div>
    );
}

export default Usecase;