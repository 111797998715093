import React from 'react';
import { Link } from 'react-router-dom';
import { motion, useInView, useAnimate  } from "framer-motion";
import { useEffect, useRef } from "react";
import '../Css/all_style.css';


const ReportSection = () => {
  // const [scope, animate] = useAnimate()
  // const motionDiv = useRef(null)
  // const isInView = useInView(motionDiv)

  // useEffect(()=>{
  //   if(isInView){
  //     animate("img", { x: 300 }, { duration:5, ease: "linear" })
  //   }else{
  //     animate("img", { x: 0 }, { duration:0 })
  //   }
  // },[isInView, animate])

  const imageRef = useRef(null);


  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        // if (entry.isIntersecting) {
        //   imageRef.current.classList.add('animate');
        //   observer.unobserve(imageRef.current); // Stop observing after the animation is triggered
        // }
        if (entry.isIntersecting) {
          imageRef.current.classList.add('animate');
        } else {
          imageRef.current.classList.remove('animate');
        }
      });
    });

    observer.observe(imageRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return(
  <div className="section-report">
    <div className="report-content">
      <h1 className="report-title">Free Attack Surface Report</h1>
      <p className="report-subtitle">
        Don't wait to secure your attack surface.<br />
        Request a free report today.
      </p>
      <Link to='/get_report'>
        <button className="use-case-btn">
          <span>Get Yours Now</span>
        </button>
      </Link>
    </div>
    <div className="report-image-wrapper">
      <div className="image-container">
        <img src="img/homepage component/asm_summary_report.png" alt="Report Preview" className="report-image hover-image" />
        <img ref={imageRef} src="img/homepage component/asm_summary_report_popup.png" alt="Report Preview" className="report-image popup-image" />
      </div>
    </div>

  </div>
)};

export default ReportSection;
