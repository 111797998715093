import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';




const Scrolltop = () => {
    const { pathname } = useLocation();  // This gets the current pathname

    useEffect(() => {
        window.scrollTo(0, 0);  // This scrolls the window to the top
    }, [pathname]);  // This useEffect hook runs every time the pathname changes

    return null;
};

export default Scrolltop;
