import React, { useState } from "react";
import '../Css/all_style.css';
import { Link } from "react-router-dom";
import { Col, Row } from 'antd';


const Tabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const openTab = (tabId) => {
    setActiveTab(tabId);
  };

  const TabAction = ({activeTab, tabKey, title}) => {
    return (
      <li className={activeTab === `"${tabKey}"` ? "active" : ""} onClick={() => openTab(`"${tabKey}"`)}>{title}</li>
    );
  }

  return (
    <div>
      <div className="tabs-container"> 
        <ul className="tabs">
          <li
            className={activeTab === "tab1" ? "active" : ""}
            onClick={() => openTab("tab1")}>ASM</li>
          <li
            className={activeTab === "tab2" ? "active" : ""}
            onClick={() => openTab("tab2")}>SCA</li>
          <li
            className={activeTab === "tab3" ? "active" : ""}
            onClick={() => openTab("tab3")}>CSPM</li>
          <li
            className={activeTab === "tab4" ? "active" : ""}
            onClick={() => openTab("tab4")}>TPRM</li>
          <li
            className={activeTab === "tab5" ? "active" : ""}
            onClick={() => openTab("tab5")}>VM</li>
        </ul>
      </div>

      {/* For tab 1 */}
      <TabContent activeTab={activeTab} tabKey={"tab1"}></TabContent>

      {/* For tab 2 */}
      <TabContent activeTab={activeTab} tabKey={"tab2"}></TabContent>

      {/* For tab 3 */}
      <TabContent activeTab={activeTab} tabKey={"tab3"}></TabContent>

      {/* For tab 4*/}
      <TabContent activeTab={activeTab} tabKey={"tab4"}></TabContent>

      {/* For tab 5*/}
      <TabContent activeTab={activeTab} tabKey={"tab5"}></TabContent>

    </div>
  );
};

const data = {
  "tab1": {
    "title": "Full Attack Surface Management",
    "title1": "Gain Complete Visibility",
    "desc1": "Identify all your IT assets, including cloud resources, on-premises infrastructure, and connected devices.",
    "title2": "Uncover Hidden Risks",
    "desc2": "Continuously monitor your attack surface for vulnerabilities, misconfigurations, and unauthorized exposures.",
    "videoId": "h82tQHrupmY?si=jf7vq8qZkqsbCAWx",
    "link": "/asm"
  },
  "tab2": {
    "title": "Software Composition Analysis",
    "title1": "Secure Your Codebase",
    "desc1": "Identify and track all components within your applications, including open-source libraries and third-party dependencies.",
    "title2": "Mitigate Security Vulnerabilities",
    "desc2": "Proactively identify known vulnerabilities within your open-source dependencies and stay updated on potential threats through automated license tracking and threat advisories.",
    "videoId": "-hbblt_GNSM?si=8ULt1FVEmEit4h_S",
    "link": "/sca"
  },
  "tab3": {
    "title": "Cloud Security Posture Management",
    "title1": "Strengthen Your Cloud Defenses",
    "desc1": "Proactively identify and address security risks across your cloud environments, reducing your attack surface.",
    "title2": "Maintain Continuous Compliance",
    "desc2": "Monitor your cloud infrastructure for misconfigurations and ensure adherence to industry security standards and compliance regulations.",
    "videoId": "eXcGksW_Uyo?si=NhfWNGSWUJWtgjfc",
    "link": "/cspm"
  },
  "tab4": {
    "title": "Third Party Risk Management",
    "title1": "Streamlined Onboarding and Management",
    "desc1": "Simplify onboarding vendors with security scoring and automated workflows for due diligence. Manage contracts and conduct dynamic risk assessments through a centralized platform.",
    "title2": "Proactive Risk Mitigation",
    "desc2": "Tier your vendors based on risk profile and track identified issues within a centralized tracker. This proactive approach allows you to prioritize remediation efforts and prevent security breaches.",
    "videoId": "1hMGxfoBAJ4?si=8e0UZyHU3nqqVhkb",
    "link": "/tprm"
  },
  "tab5": {
    "title": "Vulnerability Management",
    "title1": "Centralized Threat Management",
    "desc1": "Gain a single source of truth for all your vulnerabilities across applications, systems, and cloud environments.",
    "title2": "Prioritize Remediation Efforts",
    "desc2": "Leverage vulnerability scoring and exploitability data to focus your resources on the most critical threats first.",
    "videoId": "HpTcsjxPXJ0?si=QksTTLzt-arVl2Rr",
    "link": "/vm"
  }
}

const TabContent = ({activeTab, tabKey}) => {

  const selectedTab = data[tabKey]
  return (
    <div id={tabKey} className={`content ${activeTab === `${tabKey}` ? "active" : ""}`}>
      {/* <img src="img/vm.png" alt="Software Security Icon" /> */}
      <Row gutter={[40,16]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <YouTubeEmbed videoId={ selectedTab.videoId } />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="info-card">
            <h2>{selectedTab.title}</h2>
            <ul>
              <li><h4>{selectedTab.title1}</h4></li>
              <p>{selectedTab.desc1}</p>
              
              <li><h4>{selectedTab.title2}</h4></li>
              <p>{selectedTab.desc2}</p>
            </ul>
            <Link to={selectedTab.link} className="use-case-btn">Know More</Link>
          </div>
        </Col>
      </Row>
    </div>
  
  );
}

const YouTubeEmbed = ({ videoId }) => {
  return (
    // <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
    <div className="videoWrapper" style={{alignItems: "center"}}>
      {/* <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video player"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      /> */}

      <iframe width="100%" height="315" 
      src={`https://www.youtube.com/embed/${videoId}`} 
      title="YouTube video player" 
      frameBorder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      referrerPolicy="strict-origin-when-cross-origin" 
      allowFullScreen></iframe>
    </div>
  );
};

export default Tabs;
