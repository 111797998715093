import React from 'react';
import Herosection from './HeroSection/Herosection';
import Contactsection from './ContactSection/Contactsection';


const Index = () => {
  return (
    <div>
      <nav style={{ display: 'none' }}>
        <ul>
          <li><a href="#herosection">Herosection</a></li>
          <li><a href="#contactsection">Contactsection</a></li>
        </ul>
      </nav>
 

      <div id="herosection">
        <Herosection />
      </div>
      
      <div id="contactsection">
        <Contactsection />
      </div>
      
    </div>
  );
};
 
export default Index;
