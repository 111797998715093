import React from 'react';
import Herosection from './Herosection';
import Servicesection from './Servicesection';
import Productsection from './Productsection';
import Contactsection from '../Mainindex/Contactsection';

const Service = () => {
    return (
    <div>
      <nav style={{ display: 'none' }}>
        <ul>
          <li><a href="#herosection">Herosection</a></li>
          <li><a href="#servicesection">Servicesection</a></li>
          <li><a href="#productsection">Productsection</a></li>
          <li><a href="#contactsection">Contactsection</a></li> 
        </ul>
      </nav>

      <div id="herosection">
        <Herosection />
      </div>

       <div id="servicesection">
        <Servicesection />
      </div>

      <div id="productsection">
        <Productsection />
      </div>

      <div id="contactsection">
        <Contactsection />
      </div> 
      
    </div>
  );
};

export default Service;