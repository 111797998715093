import React, { useState } from "react";
import "../Css/all_style.css";
import Experiancebreach from "../ExperianceBreach/Experiancebreach";

const BreachSection = () => {
  const [isSidebarActive, setSidebarActive] = useState(false);
  const openSidebar = () => {
    setSidebarActive(true);
  };

  const closeSidebar = () => {
    setSidebarActive(false);
  };
  return (
    <section className="breach-section">
      <div className="breach-content">
        <div className="breach-title">Experienced a Breach?</div>
        <button className="button breach-btn" onClick={openSidebar}>
          Get Free Assistance
        </button>
      </div>
      <Experiancebreach isActive={isSidebarActive} closeForm={closeSidebar} />
    </section>
  );
};

export default BreachSection;
