import React from "react";
import '../../Css/all_style.css';

const Statsection = () => {
    return (
        <div className="product-stats-section">
            <div className="product-stats-column">
                <h2>Proactive Cloud Threat Detection.</h2>
                <p>Identify and address security misconfigurations, before they become exploits.</p>
            </div>
            <div className="product-stats-column product-stats-box">
                <h3>80%</h3>
                <h4>Cloud Breaches Start With Misconfigurations</h4>
                <p>Secure your cloud infrastructure and prevent breaches.</p>
            </div>
            <div className="product-stats-column product-stats-box">
                <h3>95%</h3>
                <h4>Vulnerabilities Exploited within 24 Hrs.</h4>
                <p>Repond Quickly with proactive threat detection.</p>
            </div>
        </div>
    );
}

export default Statsection;