import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import alertify from "alertifyjs"; // Ensure you've installed alertifyjs
import "../Css/all_style.css"; // Adjusted path to CSS file

const Contactsection = () => {
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    company: "SecureNexus",
    product: "",
    message: ""
  });

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      alertify.error("Please complete the reCAPTCHA");
      return;
    }

    const { firstName, lastName, email, mobile, company, product, message } = formData;

    const responseBody = {
      firstName,
      lastName,
      email,
      productsServices: product,
      mobile,
      company,
      message,
      recaptcha: recaptchaValue
    };

    fetch("https://api.x-bizcore.com/inquiry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(responseBody)
    })
      .then((response) => {
        if (response.ok) {
          alertify.success("Form submitted successfully");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            company: "SecureNexus",
            product: "",
            message: ""
          });
          setRecaptchaValue(null); // Reset reCAPTCHA
        } else {
          alertify.error("Error submitting form");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alertify.error("An error occurred while submitting the form");
      });
  };

  return (
    <div className="container" style={{ maxWidth: '100%'}}>
      <div className="image-column">
        <img src="img/contactus.png" alt="Protection" style={{ width: '90%' }} />
      </div>
      <div className="form-column">
        <div className="contact-section">
          <h2 className="contact-title">Get in touch today</h2>
          <p className="contact-description">
            Enhance your cyber resilience with our tailored strategies and proactive approach—partner with SecureNexus to safeguard your digital assets.
          </p>
          <div className="form-container">
            <form className="form" onSubmit={handleSubmit}>
              <div className="form-group double">
                <div className="input-container">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group double">
                <div className="input-container">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Email ID"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="mobile">Mobile No.</label>
                  <input
                    type="tel"
                    id="mobile"
                    name="mobile"
                    placeholder="Mobile No."
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group double">
                <div className="input-container">
                  <label htmlFor="company">Company</label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    placeholder="Company Name"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="product">Product</label>
                  <input
                    type="text"
                    id="product"
                    name="product"
                    placeholder="Product"
                    value={formData.product}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Enter your message here"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey="6LeMo0UnAAAAAAZTi3aRnmCpP2TgSNI1xjM5P5h6"
                  onChange={handleRecaptchaChange}
                />
              </div>
              <div className="form-group">
                <input className="use-case-btn" type="submit" value="Send A Message" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactsection;
