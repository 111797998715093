import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import '../../Css/all_style.css';
import Requestdemo from '../../Requestdemo/Requestdemo';

const Herosection = () => {
  const [isSidebarActive, setSidebarActive] = useState(false);

  const openSidebar = () => {
    setSidebarActive(true);
  };

  const closeSidebar = () => {
    setSidebarActive(false);
  };
    useEffect(() => {
        const watchDemoButton = document.getElementById('watch-demo');
        const modal = document.getElementById('demo-modal');
        const iframe = document.getElementById('youtube-video');
     
        const openModal = (event) => {
          event.preventDefault();
          modal.style.display = 'block';
        };
     
        const closeModal = () => {
          modal.style.display = 'none';
          // Reset the iframe src to stop the video
          const src = iframe.src;
          iframe.src = '';
          iframe.src = src;
        };
     
        watchDemoButton.addEventListener('click', openModal);
     
        window.onclick = function (event) {
          if (event.target === modal) {
            closeModal();
          }
        };
     
        return () => {
          watchDemoButton.removeEventListener('click', openModal);
          window.onclick = null;
        };
      }, []);

    return (
        <div id="herosection">
            <div id="top">
                <div className="product-hero-section">
                    <video className="background-video" autoPlay loop muted playsInline>
                        <source src="img/bg_video1.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="product-hero-content">
                        <h4>Third Party Risk Management (TPRM)</h4>
                        <h1 >Vet Vendors. Manage Risks. Build Trust</h1>
                        <p >Streamline vendor onboarding, automate risk assessments, and gain real-time insights to ensure you're
                            working with reliable partners.Build trust with secure and transparent supply chain.</p>
                        <div className="button-container">
                        <Link  className="button button-primary"  onClick={openSidebar}>Schedule A Demo</Link>
                            <a id="watch-demo" href="#watch-demo" className="button button-secondary">Watch demo</a>
                        </div>
                        <div className="product-hero-image">
                            <img src="img/TPRM_Product_page/Group 289146.svg" alt="Dashboard Preview"/>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sidebar */}
            <Requestdemo isActive={isSidebarActive} closeForm={closeSidebar} />

            <div id="demo-modal" className="modal" style={{display:'none', position:'fixed', zIndex:'100', left:'0', top:'0', width:'100$', height:'100%', overflow:'auto', backgroundColor:'rgba(0,0,0,0.4)'}}>
                <div className="modal-content" style={{backgroundcolor:'#fefefe', margin:'1% auto', padding:'20px', border:'1px solid #888', width:'100%', maxwidth:'800px'}}>
                <iframe id="youtube-video" width="100%" height="450" src="https://www.youtube.com/embed/1hMGxfoBAJ4?si=OhzXazTb8XmcYWa4" title="SecureNexus TPRM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>

        </div>
    
    );
}

export default Herosection;