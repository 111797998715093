import React from 'react';
import '../../Css/all_style.css';
import { Link } from 'react-router-dom';

const Usecase = () => {
    return (
        <div className="use-case-container">
            <div className="use-case-content">
                <div className="use-case-text">
                    <h2>Use Case</h2>
                    <p>SecureNexus CSPM empowers organizations of all sizes to gain continuous visibility and automated
                        control over their cloud security posture. Our comprehensive solution identifies and addresses
                        security misconfigurations,
                        proactively detects threats, and simplifies compliance management. .</p>
                    <p>This proactive approach ensures your cloud environment remains secure, compliant, and optimized for
                        performance. </p>
                    <ul className="section-list">
                        <li>Accelerate Cloud Adoption with Confidence</li>
                        <li>Improve Cloud Security Posture</li>
                        <li>Reduce Cloud Security Costs</li>
                        <li>Simplify Compliance Management</li>
                    </ul>

                    <Link to='/about'> <a  className="use-case-btn">Know More</a></Link>
                    </div>
                <div className="use-case-image">
                    <img src="img/CSPM_Product_page/image 129.svg" alt="Use Case Image" />
                </div>
            </div>
        </div>
    );
}

export default Usecase;