import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import alertify from "alertifyjs"; // Ensure you've installed alertifyjs
import "./Requestdemo.css"; // Adjusted path to CSS file

const SidebarModal = ({ isActive, closeForm }) => {
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    company: "SecureNexus",
    product: "",
    message: ""
  });

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      alertify.error("Please complete the reCAPTCHA");
      return;
    }

    const { firstName, lastName, email, mobile, company, product, message } = formData;

    const responseBody = {
      firstName,
      lastName,
      email,
      productsServices: product,
      mobile,
      company,
      message,
      recaptcha: recaptchaValue
    };

    fetch("https://api.x-bizcore.com/inquiry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(responseBody)
    })
      .then((response) => {
        if (response.ok) {
          alertify.success("Form submitted successfully");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            company: "SecureNexus",
            product: "",
            message: ""
          });
          setRecaptchaValue(null); // Reset reCAPTCHA
        } else {
          alertify.error("Error submitting form");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alertify.error("An error occurred while submitting the form");
      });
  };

  return (
    <div className={`sidebar-modal ${isActive ? "active" : ""}`}>
      <div className="sidebar-modal-content">
        <button className="sidebar-modal-close-btn" onClick={closeForm}>
          ×
        </button>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-12 formimg">
            <div className="contact-image">
              <img src="./img/schduleDemo.png" alt="image" />
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-12 demo-container">
            <div className="demo-form-container">
              <div className="form-fields">
                <h2>Schedule a demo</h2>
                <p>
                  Enhance your cyber resilience with our tailored strategies and
                  proactive approach—partner with SecureNexus to safeguard your
                  digital assets.
                </p>
                <form className="form" onSubmit={handleSubmit}>
                  <div className="form-row">
                    <input
                      className="shadow-box form-redius"
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                    <input
                      className="shadow-box form-redius"
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-row">
                    <input
                      className="shadow-box form-redius"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <input
                      className="shadow-box form-redius"
                      type="tel"
                      name="mobile"
                      placeholder="Mobile Number"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-row">
                    <input
                      className="shadow-box form-redius"
                      type="text"
                      name="product"
                      placeholder="Product"
                      value={formData.product}
                      onChange={handleChange}
                      required
                    />
                    <input
                      className="shadow-box form-redius"
                      type="text"
                      name="company"
                      placeholder="Company"
                      value={formData.company}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <textarea
                    className="shadow-box form-redius mb-3"
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>

                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 col-md-12 d-md-flex justify-content-center">
                      <ReCAPTCHA
                        sitekey="6LeMo0UnAAAAAAZTi3aRnmCpP2TgSNI1xjM5P5h6"
                        onChange={handleRecaptchaChange}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 mt-3 mt-lg-0 mt-md-3">
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarModal;
