import React from 'react';
import { Link } from 'react-router-dom';
import '../../Css/all_style.css';

const Usecase = () => {
    return (
        <div className="use-case-container">
            <div className="use-case-content">
                <div className="use-case-text">
                    <h2>Use Case</h2>
                    <p>SecureNexus Attack Surface Management (ASM) empowers businesses of all sizes to gain complete control over their digital landscape. Our advanced solution provides continuous monitoring and real-time threat detection, allowing you to proactively identify and address vulnerabilities before attackers can exploit them.</p>
                    <p>This comprehensive visibility ensures you don't have any blind spots in your IT infrastructure. From on-premises systems to cloud environments and connected devices, SecureNexus ASM tracks all assets. This empowers you to prioritize vulnerabilities and strengthen your overall security posture with confidence.</p>
                   <Link to='/about'> <a  className="use-case-btn">Know More</a></Link>
                </div>
                <div className="use-case-image">
                    <img src="img/use.png" alt="Use Case Image" />
                </div>
            </div>
        </div>
    );
}

export default Usecase;