import React from "react";
import '../Css/all_style.css';

const WhyUsSection = () => {
    return (
      <section className="why-us">
        <div className="why-us-container">
          <div className="why-us-content">
            <h3 className="why-us-title">Why Us</h3>
            <p className="why-us-description">
              We empower security leaders at high-risk organizations to
              proactively manage cyber threats. Trusted by top banks and
              insurers, our solutions and expertise help clients achieve
              unparalleled visibility into their attack surface, strengthen cloud
              security, and mitigate vulnerabilities across their applications and
              infrastructure.
            </p>
            <a href="https://www.xbizventures.com/" className="use-case-btn">Know More</a>

          </div>
          <div className="why-us-image">
            <img src="img/why-us.png" alt="Professional" />
          </div>
        </div>
      </section>
    );
  }
  
  export default WhyUsSection;
