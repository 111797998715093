import React, { useState } from 'react';
import '../../Css/all_style.css';

const Faqsection = () => {
    const [activeFaq, setActiveFaq] = useState(null);

    const toggleFaq = (faqId) => {
        setActiveFaq(activeFaq === faqId ? null : faqId);
    };

    return (
        <div>
            <section className="faq">
                <div className="product-section">
                    <h2 className="product-title">Frequently Asked Questions</h2>
                </div>
                <div className="faq-wrapper">
                    <div className="faq-container">
                        <div className={`faq-item ${activeFaq === 'faq1' ? 'active' : ''}`} onClick={() => toggleFaq('faq1')}>
                            <h3 className="faq-question">How can TPRM help me with vendor onboarding?</h3>
                            <div id="faq1" className="faq-answer" style={{ display: activeFaq === 'faq1' ? 'block' : 'none' }}>
                                <p>TPRM simplifies vendor onboarding with automated workflows, dynamic forms, standardized assessments, and centralized data management. This reduces manual effort, ensures consistent evaluation, and gets you up and running with new vendors faster.</p>
                            </div>
                        </div>
                        <div className={`faq-item ${activeFaq === 'faq2' ? 'active' : ''}`} onClick={() => toggleFaq('faq2')}>
                            <h3 className="faq-question">How does TPRM leverage Attack Surface Management (ASM) for risk analysis?</h3>
                            <div id="faq2" className="faq-answer" style={{ display: activeFaq === 'faq2' ? 'block' : 'none' }}>
                                <p>TPRM integrates with ASM to analyze vendors' public assets, uncovering potential vulnerabilities beyond self-reported information. This enhances risk scoring and helps you proactively address threats before they can be exploited.</p>
                            </div>
                        </div>
                        <div className={`faq-item ${activeFaq === 'faq3' ? 'active' : ''}`} onClick={() => toggleFaq('faq3')}>
                            <h3 className="faq-question">Can I customize risk scoring metrics within TPRM to align with my industry?</h3>
                            <div id="faq3" className="faq-answer" style={{ display: activeFaq === 'faq3' ? 'block' : 'none' }}>
                                <p>Absolutely! SecureNexus TPRM allows you to customize risk scoring by weighting risk factors based on your industry's priorities (e.g., data sensitivity) and setting custom risk tolerance thresholds. You can even leverage pre-built industry-specific templates for a head start.</p>
                            </div>
                        </div>
                        <div className={`faq-item ${activeFaq === 'faq4' ? 'active' : ''}`} onClick={() => toggleFaq('faq4')}>
                            <h3 className="faq-question">Does SecureNexus offer any free trials or demos for TPRM?</h3>
                            <div id="faq4" className="faq-answer" style={{ display: activeFaq === 'faq4' ? 'block' : 'none' }}>
                                <p>Yes! We offer free trials of SecureNexus TPRM to let you explore the platform firsthand. Additionally, our team can provide personalized demos tailored to your specific third-party risk management needs. Contact us today to learn more!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Faqsection;
