import React from "react";
import '../../Css/all_style.css';

const Faqsection = () => {
    const toggleFaq = (faqId) => {
        const answer = document.getElementById(faqId);
        const isActive = answer.parentNode.classList.contains('active');

        // Close all open answers
        const allAnswers = document.querySelectorAll('.faq-answer');
        allAnswers.forEach((ans) => {
            if (ans.id !== faqId) {
                ans.style.display = 'none';
                ans.parentNode.classList.remove('active');
            }
        });

        // Toggle the clicked answer
        if (isActive) {
            answer.style.display = 'none';
            answer.parentNode.classList.remove('active');
        } else {
            answer.style.display = 'block';
            answer.parentNode.classList.add('active');
        }
    };

    return (
        <section className="faq">
            <div className="product-section">
                <h2 className="product-title">Frequently Asked Questions</h2>
            </div>
            <div className="faq-wrapper">
                <div className="faq-container">
                    <div className="faq-item" onClick={() => toggleFaq('faq1')}>
                        <h3 className="faq-question">What cloud environments does CSPM support?</h3>
                        <div id="faq1" className="faq-answer">
                            <p>SecureNexus CSPM offers comprehensive support for a wide range of cloud environments,
                                including major providers like Amazon Web Services (AWS), Microsoft Azure, Google Cloud
                                Platform (GCP), Oracle Cloud and Digital Ocean.</p>
                        </div>
                    </div>
                    <div className="faq-item" onClick={() => toggleFaq('faq2')}>
                        <h3 className="faq-question">Does CSPM require any agent installations within my cloud environment?</h3>
                        <div id="faq2" className="faq-answer">
                            <p>No, SecureNexus CSPM is an agentless solution. It leverages existing APIs and configurations
                                within your cloud environments to gather data and identify security risks.</p>
                        </div>
                    </div>
                    <div className="faq-item" onClick={() => toggleFaq('faq3')}>
                        <h3 className="faq-question">Can CSPM help me reduce the cost of securing my cloud infrastructure?</h3>
                        <div id="faq3" className="faq-answer">
                            <p>Yes, SecureNexus CSPM offers several ways to optimize your cloud security spend. Automation
                                streamlines security tasks, proactive threat detection minimizes incident response costs,
                                and improved visibility allows for optimized resource utilization. Overall, CSPM helps you
                                achieve a more secure cloud environment while reducing the overall cost of cloud security.
                            </p>
                        </div>
                    </div>
                    <div className="faq-item" onClick={() => toggleFaq('faq4')}>
                        <h3 className="faq-question">Does SecureNexus offer any free trials or demos for CSPM?</h3>
                        <div id="faq4" className="faq-answer">
                            <p>Absolutely SecureNexus CSPM offers free trials that allow you to explore the platform's
                                features and experience its value firsthand. Additionally, our team is happy to provide
                                personalized demos tailored to your specific cloud security needs. Contact us today to learn
                                more!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faqsection;
