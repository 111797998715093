import React, { useEffect } from "react";
import '../../Css/all_style.css';

// Ensure alertify and grecaptcha are defined globally
/* global alertify, grecaptcha */

const Contactsection = () => {
    useEffect(() => {
        // Load the reCAPTCHA script
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        const handleFormSubmit = (event) => {
            event.preventDefault(); // Prevent default form submission

            // Set alertify defaults
            alertify.defaults = {
                notifier: {
                    position: 'top-center',
                    delay: 3000 // Set the default delay to 3000 milliseconds (3 seconds)
                }
            };

            // Get reCAPTCHA response
            const response = grecaptcha.getResponse();
            if (response.length === 0) {
                alertify.error('Please complete the reCAPTCHA');
                return;
            }

            const form = event.target;
            const formData = new FormData(form);

            // Create JSON object from form data
            const jsonData = {};
            formData.forEach((value, key) => {
                jsonData[key] = value;
            });

            // Extract first name and last name from 'name' field
            const nameParts = jsonData.name.split(' ');
            const firstName = nameParts[0] || ''; // If first name is missing, set it to an empty string
            const lastName = nameParts.slice(1).join(' '); // Concatenate the remaining parts as last name

            const responseBody = {
                "firstName": firstName,
                "lastName": lastName,
                "email": jsonData.email,
                "productsServices": "SecureNexus",
                "mobile": jsonData.mobile,
                "company": jsonData.company,
                "message": jsonData.message,
                "recaptcha": response
            };

            // Send form data to server
            fetch('https://api.x-bizcore.com/inquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(responseBody)
            })
            .then((response) => {
                console.log('response in submit', response);
                if (response.ok) {
                    // Show success notification
                    alertify.success('Form submitted successfully');
                    // Reset form
                    form.reset();
                } else {
                    // Show error notification
                    alertify.error('Error submitting form');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                // Show error notification
                alertify.error('An error occurred while submitting the form');
            });
        };

        const form = document.getElementById('schedule_demo_form');
        if (form) {
            form.addEventListener('submit', handleFormSubmit);
        }

        return () => {
            if (form) {
                form.removeEventListener('submit', handleFormSubmit);
            }
        };
    }, []);

    return (
        <div>
            <div className="breach-form-column">
                <div className="contact-section" style={{backgroundColor: '#FFFFFF'}}>
                    <div className="contact-description-container">
                        <div className="container" style={{ padding:'0px 100px 0px 100px'}}>
                        <p className="contact-description" style={{ color: 'blue'}}>
                        Don't wait to secure your attack surface.<br/>Request a free report today.</p>
                        </div>
                    </div>
                    <div className="form-container">
                        <form id="schedule_demo_form">
                            <div className="form-group double">
                                <div className="input-container">
                                    <label htmlFor="name">Name</label>
                                    <input type="text" id="name" name="name" placeholder="Your Full Name" required />
                                </div>
                                <div className="input-container">
                                    <label htmlFor="mobile" >Mobile No.</label>
                                    <input type="tel" id="mobile" name="mobile" placeholder="Mobile No." required />
                                </div>
                            </div>

                            <div className="form-group double">
                                <div className="input-container">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id="email" name="email" placeholder="Enter Email ID" required />
                                </div>
                                <div className="input-container">
                                    <label htmlFor="company">Company</label>
                                    <input type="text" id="company" name="company" placeholder="Company Name" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea id="message" name="message" placeholder="Enter your message here" required></textarea>
                            </div>

                            <div className="form-group">
                                <div className="g-recaptcha" data-sitekey="6LeMo0UnAAAAAAZTi3aRnmCpP2TgSNI1xjM5P5h6"></div>
                            </div>

                            <div className="form-group">
                                <input className="use-case-btn" type="submit" value="Submit" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contactsection;
