import React, { useState } from 'react';
import '../Css/all_style.css';

const Productsection = () => {
    const [openFaq, setOpenFaq] = useState(null);

    const toggleFaq = (id) => {
        setOpenFaq(prevOpenFaq => (prevOpenFaq === id ? null : id));
    };

    return (
        <div>
            <section className="faq">
                <div className="product-section">
                    <h2 className="product-title">Frequently Asked Questions</h2>
                </div>
                <div className="faq-wrapper">
                    <div className="faq-container">
                        <div className={`faq-item ${openFaq === 'faq1' ? 'active' : ''}`} onClick={() => toggleFaq('faq1')}>
                            <h3 className="faq-question">How can SecureNexus cybersecurity services help my organization improve its overall security posture?</h3>
                            <div id="faq1" className="faq-answer">
                                {openFaq === 'faq1' && (
                                    <p>Our services proactively identify vulnerabilities, provide expert guidance, and offer in-depth assessments to help you address security gaps and strengthen your overall defense.</p>
                                )}
                            </div>
                        </div>
                        <div className={`faq-item ${openFaq === 'faq2' ? 'active' : ''}`} onClick={() => toggleFaq('faq2')}>
                            <h3 className="faq-question">Can you tailor your cybersecurity services to meet my organization's specific needs?</h3>
                            <div id="faq2" className="faq-answer">
                                {openFaq === 'faq2' && (
                                    <p>We work closely with you to understand your specific needs and threats. We then customize our services, prioritizing actions and offering ongoing support to fit your unique security landscape.</p>
                                )}
                            </div>
                        </div>
                        <div className={`faq-item ${openFaq === 'faq3' ? 'active' : ''}`} onClick={() => toggleFaq('faq3')}>
                            <h3 className="faq-question">What is the benefit of threat modeling over traditional security assessments?</h3>
                            <div id="faq3" className="faq-answer">
                                {openFaq === 'faq3' && (
                                    <p>Proactive Threat Modeling goes beyond snapshots to identify potential threats early, allowing you to build security into your systems from the start. It focuses on attacker behavior and fosters security awareness within your team.</p>
                                )}
                            </div>
                        </div>
                        <div className={`faq-item ${openFaq === 'faq4' ? 'active' : ''}`} onClick={() => toggleFaq('faq4')}>
                            <h3 className="faq-question">How do I get started with your cybersecurity services?</h3>
                            <div id="faq4" className="faq-answer">
                                {openFaq === 'faq4' && (
                                    <p>Contact our security specialists today to discuss your unique security needs and explore how SecureNexus can help. We offer a free consultation to understand your challenges and recommend the best course of action.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Productsection;
