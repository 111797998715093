import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SampleNavbar from './components/Navbar/SampleNavbar';
import Navbar from './components/Navbar/Navbar';
import Mainindex from './components/Mainindex/Mainindex';
import Footer from './components/Footer';
import About from './components/About';
import Services from './components/Services/Services';
import Scheduledemo from './components/Scheduledemo';
import Asm from './components/Asm';
import Sca from './components/Sca';
import Cspm from './components/Cspm';
import Tprm from './components/Tprm';
import Vm from './components/Vm';
import ScrollTop from './components/ScrollTop';
import PrivacyPolicy from './components/PrivacyPolicy';
import GetReport from './components/GetReport';
import Chatbot from './components/Chatbot';

import './App.css';

function App() {
  return (
    <Router>
      <ScrollTop />
      <div>
        <Navbar />
        {/* <SampleNavbar /> */}

        <Routes>
          <Route path="/" element={<Mainindex />} />
          <Route path="/asm" element={<Asm />} />
          <Route path="/sca" element={<Sca />} />
          <Route path="/cspm" element={<Cspm />} />
          <Route path="/tprm" element={<Tprm />} />
          <Route path="/vm" element={<Vm />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/schedule_demo" element={<Scheduledemo />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/get_report" element={<GetReport />} />
          <Route path="/chatbot" element={<Chatbot />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;

