import React, { useState, useEffect } from 'react';
import '../../Css/all_style.css';

const Productsection = () => {
    const [openFaq, setOpenFaq] = useState(null);

    const toggleFaq = (id) => {
        setOpenFaq(prevOpenFaq => (prevOpenFaq === id ? null : id));
    };

    useEffect(() => {
        console.log('Current open FAQ:', openFaq);
    }, [openFaq]);

    return (
        <div>
            <section className="faq">
                <div className="product-section">
                    <h2 className="product-title">Frequently Asked Questions</h2>
                </div>
                <div className="faq-wrapper">
                    <div className="faq-container">
                        <div className={`faq-item ${openFaq === 'faq1' ? 'active' : ''}`} onClick={() => toggleFaq('faq1')}>
                            <h3 className="faq-question">How can I get started with improving my organization's cybersecurity?</h3>
                            <div className={`faq-answer ${openFaq === 'faq1' ? 'active' : ''}`}>
                                <p>Conduct a security risk assessment to identify vulnerabilities.
                                    Implement basic security measures like multi-factor authentication and strong password policies.
                                    Educate employees on cybersecurity best practices like phishing awareness.
                                    Explore solutions like attack surface management (ASM) for continuous monitoring.</p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFaq === 'faq2' ? 'active' : ''}`} onClick={() => toggleFaq('faq2')}>
                            <h3 className="faq-question">What is attack surface monitoring (ASM), and how can it benefit my organization?</h3>
                            <div className={`faq-answer ${openFaq === 'faq2' ? 'active' : ''}`}>
                                <p>ASM provides continuous visibility into your entire IT infrastructure, including devices, applications,
                                    and cloud resources.
                                    This allows you to identify and address potential security weaknesses before they can be exploited by
                                    attackers.
                                    ASM helps you maintain a strong security posture and reduce your overall attack surface.</p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFaq === 'faq3' ? 'active' : ''}`} onClick={() => toggleFaq('faq3')}>
                            <h3 className="faq-question">What types of security assessments does SecureNexus offer?</h3>
                            <div className={`faq-answer ${openFaq === 'faq3' ? 'active' : ''}`}>
                                <p>SecureNexus offers a variety of security assessments, including vulnerability assessments, cloud security
                                    assessments, and penetration testing.
                                    These assessments help identify security weaknesses in your systems and applications.
                                    We can tailor assessments to your specific needs and industry regulations.</p>
                            </div>
                        </div>
                        <div className={`faq-item ${openFaq === 'faq4' ? 'active' : ''}`} onClick={() => toggleFaq('faq4')}>
                            <h3 className="faq-question">Can I see a demo of your products before I buy?</h3>
                            <div className={`faq-answer ${openFaq === 'faq4' ? 'active' : ''}`}>
                                <p>Absolutely! Contact us today to schedule a personalized demo of our security solutions.
                                    We'll walk you through how our products can help you achieve your specific security goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Productsection;
