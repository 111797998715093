import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Css/all_style.css';

const HeroSection = () => {
  useEffect(() => {
    const watchDemoButton = document.getElementById('watch-demo');
    const demoModal = document.getElementById('demo-modal');

    if (watchDemoButton && demoModal) {
      const openModal = (event) => {
        event.preventDefault();
        demoModal.style.display = 'block';
      };

      const closeModal = (event) => {
        if (event.target === demoModal) {
          demoModal.style.display = 'none';
        }
      };

      watchDemoButton.addEventListener('click', openModal);
      window.addEventListener('click', closeModal);

      return () => {
        watchDemoButton.removeEventListener('click', openModal);
        window.removeEventListener('click', closeModal);
      };
    }
  }, []);

  return (
    <div>
      <div id="top">
        <div className="hero-container">
          <video className="background-video" autoPlay loop muted playsInline>
            <source src="img/bg_video1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="video-overlay"></div>
          <div className="hero">
            <div className="hero-content">
              <h1>One-Stop Solution For All Your Cybersecurity Needs</h1>
              <p>Our tightly integrated ecosystem, low-code platform, and homegrown tools provide the visibility, context, and control necessary to enhance your cybersecurity posture</p>
              <div className="button-container">
                <Link to="/Asm" className="button button-primary">Learn More</Link>
                <a id="watch-demo" href="#watch-demo" className="button button-secondary">Watch demo</a>
              </div>
            </div>
            <div className="hero-image">
              {/* <img src="img/home page/Group_289136.svg" alt="Dashboard Preview" /> */}
              <img src="img/home page/Dashboard_Homepage_Hero.svg" alt="Dashboard Preview" />
            </div>
          </div>
        </div>

        <div id="demo-modal" className="modal" style={{ display: 'none', position: 'fixed', zIndex: '100', left: '0', top: '0', width: '100vw', height: '100%', overflow: 'auto', backgroundColor: 'rgba(0,0,0,0.4)' }}>
          <div className="modal-content" style={{ backgroundColor: '#fefefe', margin: '1% auto', padding: '20px', border: '1px solid #888', width: '80%', maxWidth: '750px' }}>
            <iframe width="100%" height="450" src="https://www.youtube.com/embed/h82tQHrupmY?si=PUVoqRHoYVLTvi4r" title="SecureNexus FASM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;