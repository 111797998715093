import React from "react";
import '../../Css/all_style.css';
import { Link } from 'react-router-dom';

const Usecase = () => {
    return (
        <div className="use-case-container">
            <div className="use-case-content">
                <div className="use-case-text">
                    <h2>Use Case</h2>
                    <p>SecureNexus TPRM empowers organizations of all sizes to gain control over their third-party risk
                        landscape. Our comprehensive solution streamlines vendor onboarding, automates risk assessments, and
                        provides continuous monitoring of vendor security posture.
                        This proactive approach allows you to identify and address potential risks before they can disrupt
                        your business.</p>
                    <p>This comprehensive visibility ensures you don't have any blind spots in your IT infrastructure. From
                        on-premises systems to cloud environments and connected devices, SecureNexus ASM tracks all assets.
                        This empowers you to prioritize vulnerabilities and strengthen your overall security posture with
                        confidence.</p>
                    <ul className="section-list">
                        <li>Simplify Vendor Onboarding</li>
                        <li>Proactive Risk Management</li>
                        <li>Enhanced Compliance Management</li>
                        <li>Improved Decision-Making</li>
                    </ul>

                    <Link to='/about'> <a  className="use-case-btn">Know More</a></Link>
                    </div>
                <div className="use-case-image">
                    <img src="img/TPRM_Product_page/image 129.svg" alt="Use Case Image" />
                </div>
            </div>
            </div>
    );
}

export default Usecase;