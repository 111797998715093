import React from "react";
import '../../Css/all_style.css';

const Statsection = () => {
    return (
        <div className="product-stats-section">
            <div className="product-stats-column">
                <h2>Identifiy Third-Party Vulnerabilities.</h2>
                <p>Proactively identify and adddress security risks within vendor ecosystem.</p>
            </div>
            <div className="product-stats-column product-stats-box">
                <h3>60%</h3>
                <h4>Data Breaches Involve Third-Party Vendors</h4>
                <p>Secure your supply chain and minimize the attack surface.</p>
            </div>
            <div className="product-stats-column product-stats-box">
                <h3>$5.7M</h3>
                <h4>Third-Party Risks Cost Businesses an Average of $5.7 Millions per Year.</h4>
                <p>Mitigate risks and protect your bottom-line with TPRM.</p>
            </div>
        </div>
    );
}

export default Statsection;