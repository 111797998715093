import React from "react";
import '../../Css/all_style.css';

const Featuresection = () => {
    return (
        <div className="product-features-section">
    <div className="product-features-header">
      <h2>Features</h2>
      <p>Gain Complete Visibility, Secure Your Digital Landscape with Confidence.</p>
    </div>
    
    <div className="product-features-content">
      <div className="product-features-image">
        <img src="img/FASM_Product_page/Group 289140.svg" alt="Features Image" />
      </div>
      
      <div className="product-features-text">
        <div className="feature-item">
          <div><span className="feature-icon"></span></div>
          <div className="feature-text">
            <h3>Risk Quantification</h3>
            <p>Quantify the risks associated with applications, domains, servers, networks and more, to prioritize security efforts.</p>
          </div>
        </div>
        
        <div className="feature-item">
          <div><span className="feature-icon"></span></div>
          <div className="feature-text">
            <h3>Exposed Data Detection</h3>
            <p>Identify exposed sensitive data on the public internet, including configuration errors, leaked credentials, and misconfigured services.</p>
          </div>
        </div>
        
        <div className="feature-item">
          <div><span className="feature-icon"></span></div>
          <div className="feature-text">
            <h3>Dark Web Monitoring</h3>
            <p>Monitor the dark web for potential leaks of your sensitive data, exposed credentials, or brand mentions associated with cyber threats.</p>
          </div>
        </div>
      </div>
    </div>


{/* <!-- Additional Features Section with Reversed Columns --> */}
<div className="product-features-section">
    <div className="product-features-content reverse-row">
      <div className="product-features-text">
        <div className="feature-item">
          <div><span className="feature-icon"></span></div>
          <div className="feature-text">
            <h3>Advanced Asset Discovery</h3>
            <p>Uncover hidden assets and shadow IT with our advanced techniques, providing comprehensive visibility into your entire digital landscape and eliminating blind spots.</p>
          </div>
        </div>
        {/* <!-- More feature items can be added here if necessary --> */}
        <div className="feature-item">
            <div><span className="feature-icon"></span></div>
            <div className="feature-text">
              <h3>Comprehensive Monitoring</h3>
              <p>Gain real-time visibility into your entire attack surface, including internal and external assets, for proactive threat detection and vulnerability remediation.</p>
            </div>
          </div>
          <div className="feature-item">
            <div><span className="feature-icon"></span></div>
            <div className="feature-text">
              <h3>Continuous Security Testing (CST)</h3>
              <p>Go beyond automated tools with our CST program. Our team of experts works alongside ASM to provide unmatched security insights and proactive threat hunting.</p>
            </div>
          </div>
      </div>

      <div className="product-features-image">
        <img src="img/FASM_Product_page/image.svg" alt="Additional Features Image" />
      </div>
    </div>
 

 <div className="product-features-content">
    <div className="product-features-image">
      <img src="img/FASM_Product_page/Group 289141.svg" alt="Features Image" />
    </div>
    
    <div className="product-features-text">
      <div className="feature-item">
        <div><span className="feature-icon"></span></div>
        <div className="feature-text">
          <h3>Unify & Secure Your Global Enterprise</h3>
          <p>Gain a single, consolidated view of your attack surface across all subsidiaries, empowering centralized security management and risk mitigation for your entire organization</p>
        </div>
      </div>
      
      <div className="feature-item">
        <div><span className="feature-icon"></span></div>
        <div className="feature-text">
          <h3>Group-Level Security Oversight</h3>
          <p>Empower group management with centralized visibility into the security posture of all subsidiaries. Access detailed security reports, drill down into specific subsidiary data, and make informed decisions for enterprise-wide security strategy.</p>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
    );
}


export default Featuresection;