import React, { useState } from 'react';
import '../Css/all_style.css';


const FAQSection = () => {
  const [activeFAQ, setActiveFAQ] = useState(null);

  const toggleFaq = (faqId) => {
    setActiveFAQ(activeFAQ === faqId ? null : faqId);
  };

  return (
    <section className="faq">
      <div className="product-section">
        <h2 className="product-title">Frequently Asked Questions</h2>
      </div>
      <div className="faq-wrapper">
        <div className="faq-container">
          <div className={`faq-item ${activeFAQ === 'faq1' ? 'active' : ''}`} onClick={() => toggleFaq('faq1')}>
            <h3 className="faq-question">How can I get started with improving my organization's cybersecurity?</h3>
            <div id="faq1" className="faq-answer" style={{ display: activeFAQ === 'faq1' ? 'block' : 'none' }}>
              <p>Conduct a security risk assessment to identify vulnerabilities. Implement basic security measures like multi-factor authentication and strong password policies. Educate employees on cybersecurity best practices like phishing awareness. Explore solutions like attack surface management (ASM) for continuous monitoring.</p>
            </div>
          </div>
          <div className={`faq-item ${activeFAQ === 'faq2' ? 'active' : ''}`} onClick={() => toggleFaq('faq2')}>
            <h3 className="faq-question">What is attack surface monitoring (ASM), and how can it benefit my organization?</h3>
            <div id="faq2" className="faq-answer" style={{ display: activeFAQ === 'faq2' ? 'block' : 'none' }}>
              <p>ASM provides continuous visibility into your entire IT infrastructure, including devices, applications, and cloud resources. This allows you to identify and address potential security weaknesses before they can be exploited by attackers. ASM helps you maintain a strong security posture and reduce your overall attack surface.</p>
            </div>
          </div>
          <div className={`faq-item ${activeFAQ === 'faq3' ? 'active' : ''}`} onClick={() => toggleFaq('faq3')}>
            <h3 className="faq-question">What types of security assessments does SecureNexus offer?</h3>
            <div id="faq3" className="faq-answer" style={{ display: activeFAQ === 'faq3' ? 'block' : 'none' }}>
              <p>SecureNexus offers a variety of security assessments, including vulnerability assessments, cloud security assessments, and penetration testing. These assessments help identify security weaknesses in your systems and applications. We can tailor assessments to your specific needs and industry regulations.</p>
            </div>
          </div>
          <div className={`faq-item ${activeFAQ === 'faq4' ? 'active' : ''}`} onClick={() => toggleFaq('faq4')}>
            <h3 className="faq-question">Can I see a demo of your products before I buy?</h3>
            <div id="faq4" className="faq-answer" style={{ display: activeFAQ === 'faq4' ? 'block' : 'none' }}>
              <p>Absolutely! Contact us today to schedule a personalized demo of our security solutions. We'll walk you through how our products can help you achieve your specific security goals.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
