import React from 'react';
import { Col, Row } from 'antd';
import '../Css/all_style.css';

const data = {
  "cst": {
    "image": "cst.svg",
    "title": "Continuous Security Testing",
    "desc": "Safeguard your digital assets with our continuous, proactive security testing services, ensuring your systems are always protected."
  },
  "ast": {
    "image": "appc.svg",
    "title": "Application Security",
    "desc": "Defend your applications against threats with our thorough security assessments and expert"
  },
  "is": {
    "image": "Is.svg",
    "title": "Infrastructure Security",
    "desc": "Ensure your infrastructure's security and resilience with our advanced assessments and expert guidance."
  },
  "cs": {
    "image": "cs.svg",
    "title": "Cloud Security",
    "desc": "Assess and strengthen the security of your cloud environment with our expert assessment and recommendations."
  },
  "tm": {
    "image": "tm.svg",
    "title": "Threat Modeling",
    "desc": "Threat modelling, Penetration Testing, Vendor Evaluation, API gateway Security and Governance, DevSecOps Review, Security Workshops."
  },
  "scr": {
    "image": "scr.svg",
    "title": "Source Code Review",
    "desc": "Detect and address security flaws in your source code, preventing potential vulnerabilities and breaches."
  }
}

const ServiceCardDetail = ({keyname, card}) => {

  const dataObj = data[keyname];

  return (
    <Col xs={24} sm={12} md={8} lg={8}> 
      <div className={`service-card ${card}`}>
        <img src={`img/${dataObj.image}`} alt="Continuous Security Testing" />
        <div className="service-card-desc">
          <div className="card-heading">{dataObj.title}</div>
          <div className="paragraph7">{dataObj.desc}</div>
        </div>
      </div>
    </Col>
  );
}

const ServicesSection = () => {
  return(
  <section className="service">
    <div className="product-section">
      <div>
        <h2 className="product-title">Services</h2>
        <p className="product-description"> The Power to Protect Your Cyberspace with Complete End-To-End Security </p>
      </div>
    
      <div style={{ marginTop: "40px", gap: "20px", display: "grid" }}>
        <Row gutter={[30, 30]} style={{margin: "0px"}}>
            <ServiceCardDetail keyname="cst" card="card1" />
            <ServiceCardDetail keyname="ast" card="card2" />
            <ServiceCardDetail keyname="is" card="card3" />
            <ServiceCardDetail keyname="cs" card="card4" />
            <ServiceCardDetail keyname="tm" card="card5" />
            <ServiceCardDetail keyname="scr" card="card6" />
        </Row>
      </div>
    </div>
  </section>
);
}
export default ServicesSection;
